import React, { useState } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
} from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "../../hooks/page-title";
import {
  InputField,
  LoadingButton,
  PasswordInputField,
  PopUpMsg,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../store/reducer";
import { errorsSetter } from "../../helpers/errors-setter";
import myTranslator from "../../helpers/myTranslator";

export default function SignIn() {
  PageTitle("SignIn");
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  const [valuesErr, setValuesErr] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = React.useState({
    type: "info",
    message: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setValuesErr({});
    const url = process.env.REACT_APP_API_KEY;
    try {
      let { data } = await axios.post(url + "login", {
        email: values.email,
        password: values.password,
      });
      dispatch(loginUser(data));
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 422) {
        setValuesErr(errorsSetter(error));
      } else {
        setPayload({ message: error?.response?.data?.message, type: "error" });
      }
      setOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="sign_in_page">
        <Container maxWidth="100%" sx={{ pt: 2, pb: 2 }}>
          <Box
            component={Paper}
            sx={{
              maxWidth: "450px",
              py: 4,
              px: 4,
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              sx={{
                color: "#407BFF",
                textAlign: "center",
              }}
            >
              {myTranslator(
                translation,
                selectedLang,
                "gr_sign_in_heading_welcome"
              )}
            </Typography>
            <Box
              component="form"
              onSubmit={(e) => handleForm(e)}
              sx={{ padding: { sm: "75px 0", xs: "25px 0" } }}
            >
              <Stack direction="column" spacing={2}>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Johndoe@gmail.com"
                  size="small"
                  labelTop="gr_sign_in_label_email"
                  fullWidth
                  required
                  error={valuesErr?.email}
                  handleChange={(event) => handleChange(event)}
                />
                <PasswordInputField
                  placeholder="xxxxxx"
                  labelTop="gr_sign_in_label_password"
                  required
                  size="small"
                  fullWidth
                  error={valuesErr?.password}
                  name="password"
                  handleChange={(event) => handleChange(event)}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                mt={1}
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox size="small" defaultChecked />}
                    sx={{ "& span": { fontSize: "14px" } }}
                    label={myTranslator(
                      translation,
                      selectedLang,
                      "gr_sign_in_label_remember_me"
                    )}
                  />
                </FormGroup>
                <Button
                  variant="text"
                  type="button"
                  onClick={() => navigate("/forget-password")}
                  style={{ color: "#FF0000", fontSize: "12px" }}
                >
                  {myTranslator(
                    translation,
                    selectedLang,
                    "gr_sign_in_btn_forgot_pass"
                  )}
                </Button>
              </Stack>
              <div style={{ marginTop: "35px" }}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  type="submit"
                  style={{
                    borderRadius: "25px",
                    textTransform: "none",
                  }}
                  loading={isLoading}
                  title="gr_sign_in_btn_sign_in"
                />
              </div>
            </Box>
          </Box>
        </Container>
      </div>
      <PopUpMsg
        open={!!open}
        type={payload.type}
        message={payload.message ?? "error occurred"}
        handleClose={() => setOpen(false)}
      />
    </>
  );
}
