import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import API from "../../../axios";
import {
  UsePagination,
  PopUpMsg,
  TableWrapper,
  AddProjectBtn,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import myTranslator from "../../../helpers/myTranslator";
import { useSelector } from "react-redux";

var tableCols = ["sa_org_get_title", "sa_org_get_description", "sa_org_get_status", "sa_org_get_action"];

export default function GetOrganization() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState(null);
  const [page, setPage] = useState(1);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);  
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    type: "success",
    message: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await API(`admin/organizations?page=${page}`, "get");
      setOrganizations(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setOrganizations(null);
      setOpen(true);
      setPayload({ message: err.message, type: "error" });
    }
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Typography variant="h6" mb={2}>
          {myTranslator(translation,selectedLang,"sa_org_organizations")}
        </Typography>
        <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>{myTranslator(translation,selectedLang,column)}</TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={organizations?.data.length}
          tableStyle={{ minWidth: "400px" }}
        >
          {!!organizations &&
            organizations?.data.map((organization, index) => (
              <TableRow key={index}>
                <TableCell>{organization.title}</TableCell>
                <TableCell width="50%">
                  <Typography
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                    }}
                    variant="caption1"
                  >
                    {organization.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    label={organization.active ? myTranslator(translation,selectedLang,"sa_label_active") : "Disabled"}
                    color={organization.active ? "success" : "default"}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={myTranslator(translation,selectedLang,"sa_org_get_tooltip_edit")} placement="top">
                    <IconButton
                      onClick={() =>
                        navigate(
                          `/admin/organizations/${organization.id}`,{
                            id: organization.id,
                          }
                        )
                      }
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>
        {!!organizations && organizations?.meta?.last_page > 1 && (
          <Box component="div" sx={{ mt: 2 }}>
            <UsePagination
              total={organizations?.meta?.total}
              perPage={organizations?.meta?.per_page}
              page={page}
              setPage={setPage}
            />
          </Box>
        )}
        <PopUpMsg
          open={!!open}
          type={payload.type}
          message={payload.message ?? "error occurred"}
          handleClose={() => setOpen(false)}
        />
      </Container>
      <AddProjectBtn
        handleClick={() => navigate("/admin/organizations/create")}
        tooltip={myTranslator(translation,selectedLang,"sa_org_get_tooltip_create_org")}
      />
    </>
  );
}
