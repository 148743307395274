import React, { useState } from 'react';
import {
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    Stack,
    Divider
} from "@mui/material";

import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import API from '../../../../axios';
import { InfoHeading, InputField, LoadingButton, SearchComboBox, SelectBox } from "../../../../components";
import { openPopUp } from '../../../../store/reducer';
import { errorsSetter } from '../../../../helpers/errors-setter';
import myTranslator from '../../../../helpers/myTranslator';


function ComponentForm({ handleClose }) {
    const _user = useSelector((state) => state.storeReducer.user);
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        type: null,
        component: null,
        manufacturer: null,
        modal: null,
        organization_id: null,
        name: '',
        item_number: '',
        description: '',
        battery_storage: 0,
        energy_management: 0,
        photovoltaic: 0,
    });
    const [manufacturerItems, setManufacturerItems] = useState([]);
    const [modalItems, setModalItems] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }

    const handleComponent = (item) => {
        setFormData({ ...formData, type: item, component: item });
        if (item?.is_custom) { return; }
        let _manufacturer = [];
        if (Boolean(item?.manufacturers?.length)) {
            item?.manufacturers.forEach((x, _) => {
                return _manufacturer.push({ label: x.manufacturer, value: x.id });
            });
            setManufacturerItems(_manufacturer);
        } else {
            setManufacturerItems([{ label: 'no items' }]);
            dispatch(openPopUp({
                message: myTranslator(translation, selectedLang, "org_lib_add_comp_alert_warning_1"),
                type: 'warning',
            }));
        }
    }

    const handleModal = (e) => {
        const _id = e.target.value;
        setFormData({ ...formData, manufacturer: _id });
        let _index = formData.component.manufacturers.findIndex(x => x.id === _id);
        let _obj = formData?.component?.manufacturers[_index];
        let _modalItems = [];
        if (Boolean(_obj.modals?.length)) {
            _obj?.modals.forEach((x, _) => {
                return _modalItems.push({ label: x.title, value: x.id });
            });
            setModalItems(_modalItems);
        } else {
            setModalItems([{ label: 'no items' }]);
            dispatch(openPopUp({
                message: myTranslator(translation, selectedLang, "org_lib_add_comp_alert_warning_2"),
                type: 'warning',
            }));
        }
        return
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        var _data = {};
        if (formData.type?.is_custom) {
            _data = {
                component_type_id: formData.type?.id,
                organization_id: _user?.organization_id,
                name: formData.name,
                item_number: formData.item_number ?? '',
                description: formData.description ?? '',
                battery_storage: formData.battery_storage ? '1' : '0',
                energy_management: formData.energy_management ? '1' : '0',
                photovoltaic: formData.photovoltaic ? '1' : '0',
                is_custom: 1,
                type: 'custom',
            }
        } else {
            _data = {
                component_type_id: formData.type?.id,
                organization_id: _user?.organization_id,
                manufacturer_id: formData.manufacturer,
                modal_id: formData.modal,
                item_number: formData.item_number ?? '',
            }
        }
        setFormErrors({});
        try {
            let { data } = await API(`${_userRole}/components`, 'post', _data);
            dispatch(openPopUp({
                message: myTranslator(translation, selectedLang, "org_lib_add_comp_alert_success"),
                type: 'success',
            }));
            navigate(`/library/components/${data.id}`, { id: data.id });
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                message: myTranslator(translation, selectedLang, "org_lib_add_comp_alert_error"),
                type: 'error'
            }));
        }
    }

    return (
        <>
            <Box
                component='form'
                autoCapitalize="off"
                autoComplete="off"
                onSubmit={handleForm}
                sx={{
                    pb: 4,
                    px: 1
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="h5">
                            {myTranslator(translation, selectedLang, "org_lib_add_comp_typo_add_new_compo")}
                        </Typography>
                        <Box component="small" sx={{ color: '#969696' }}>
                            {myTranslator(translation, selectedLang, "org_lib_add_comp_typo_select_the_manuf")}
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SearchComboBox
                            label={myTranslator(translation, selectedLang, "org_lib_add_comp_label_component_type")}
                            fullWidth
                            url={`${_userRole}/component-types?active=1&`}
                            objLabel='title'
                            required
                            size='small'
                            handleChange={handleComponent}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {!!formData.type && !formData.type?.is_custom ?
                            <SelectBox
                                items={manufacturerItems}
                                label={myTranslator(translation, selectedLang, "org_lib_add_comp_label_manufacturer")}
                                disabled={Boolean(!manufacturerItems.length)}
                                required
                                fullWidth
                                size='small'
                                handleChange={handleModal}
                                error={formErrors?.manufacturer}
                            />
                            :
                            <InputField
                                size="small"
                                fullWidth
                                required
                                label={myTranslator(translation, selectedLang, "org_lib_add_comp_label_name")}
                                name='name'
                                handleChange={handleChange}
                                error={formErrors?.name}
                            />
                        }
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {!!formData.type && !formData.type?.is_custom ?
                            <SelectBox
                                items={modalItems}
                                label={myTranslator(translation, selectedLang, "org_lib_add_comp_label_modal")}
                                disabled={Boolean(!modalItems.length)}
                                required
                                fullWidth
                                size='small'
                                handleChange={(e) => setFormData({ ...formData, modal: e.target.value })}
                                error={formErrors?.modal}
                            />
                            :
                            <InputField
                                size="small"
                                fullWidth
                                label={myTranslator(translation, selectedLang, "org_lib_add_comp_label_item_no")}
                                type="number"
                                inputProps={{
                                    'min': '0'
                                }}
                                name='item_number'
                                handleChange={handleChange}
                                error={formErrors?.item_number}
                            />
                        }
                    </Grid>
                    {!!formData.type && !formData.type?.is_custom ?
                        <Grid item md={4} xs={12}>
                            <InputField
                                size="small"
                                fullWidth
                                label={myTranslator(translation, selectedLang, "org_lib_add_comp_label_item_no")}
                                type="number"
                                inputProps={{
                                    'min': '0'
                                }}
                                handleChange={handleChange}
                                name='item_number'
                                error={formErrors?.item_number}
                            />
                        </Grid>
                        : null
                    }
                    {!!formData.type && !formData.type?.is_custom ?
                        null
                        :
                        <>
                            <Grid item xs={12}>
                                <InputField
                                    size="small"
                                    fullWidth
                                    label={myTranslator(translation, selectedLang, "org_lib_label_description_op")}
                                    name='description'
                                    multiline
                                    rows="4"
                                    handleChange={handleChange}
                                    error={formErrors?.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack
                                    direction='row'
                                    spacing={1}
                                    alignItems='center'
                                >
                                    <InfoHeading variant='h6' text={myTranslator(translation, selectedLang, "org_lib_info_heading")} />
                                </Stack>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.energy_management)} name='energy_management' onChange={handleSwitchChange} size='small' />} label={myTranslator(translation, selectedLang, "org_lib_label_energy_management")} />
                                    <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.battery_storage)} name='battery_storage' onChange={handleSwitchChange} size='small' />} label={myTranslator(translation, selectedLang, "org_lib_label_battery_storage")} />
                                    <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.photovoltaic)} name='photovoltaic' onChange={handleSwitchChange} size='small' />} label={myTranslator(translation, selectedLang, "org_lib_label_photovoltaic")} />
                                </FormGroup>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Stack
                            direction='row'
                            spacing={1}
                        >
                            <LoadingButton
                                variant="contained"
                                onClick={() => { }}
                                sx={{
                                    backgroundColor: '#000',
                                    '&:hover': {
                                        backgroundColor: '#000'
                                    }
                                }}
                                disabled={isLoading}
                                type="submit"
                                loading={isLoading}
                                title="org_lib_btn_add_to"
                            />
                            <Button
                                type="button"
                                variant="contained"
                                color='error'
                                onClick={handleClose}
                            >
                                {myTranslator(translation, selectedLang, "org_lib_btn_abort")}
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Typography variant="h6" component="h6" mt={3}>
                            {myTranslator(translation, selectedLang, "org_lib_add_comp_typo_cant_find")}
                        </Typography>
                        <Box component="small" sx={{ color: '#969696' }}>
                            {myTranslator(translation, selectedLang, "org_lib_add_comp_typo")} <a href="/#">{myTranslator(translation, selectedLang, "org_lib_add_comp_typo_email")}</a>{myTranslator(translation, selectedLang, "org_lib_add_comp_typo_send")}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
ComponentForm.defaultProps = {
    handleClose: () => { }
}
export default ComponentForm;