import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { openPopUp } from "../../../store/reducer";
import { SelectBox, MyLoader, SaveChangesBtn } from "../../../components";
import myTranslator from "../../../helpers/myTranslator";

export default function PvSystem() {
  PageTitle("PV system");
  const { id } = useParams();
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    pv_system: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const _userRole = useSelector((state) => state.storeReducer.user.role);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(`${_userRole}/projects/${id}`, "get");
      setIsLoading(false);
      setFormData({
        ...formData,
        pv_system: data?.building?.pv_system || "",
        project_id: id,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setShowUpdateBtn(true);
  };

  const handleForm = async (e) => {
    e.preventDefault();
    let _fd = formData;
    setIsLoading(true);
    try {
      await API(`${_userRole}/buildings`, "post", _fd);
      setShowUpdateBtn(false);
      dispatch(
        openPopUp({
          open: true,
          message: myTranslator(
            translation,
            selectedLang,
            "org_project_pv_sys_alert_success"
          ),
          type: "success",
        })
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 422) {
        let errObj = {};
        for (const [key, value] of Object.entries(
          error?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
        return;
      }
      dispatch(
        openPopUp({
          open: true,
          message: myTranslator(
            translation,
            selectedLang,
            "org_project_pv_sys_alert_error"
          ),
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <Typography
          variant="h5"
          component="h5"
          fontSize={"18px"}
          mt={"12px"}
          mb={"10px"}
        >
          {myTranslator(
            translation,
            selectedLang,
            "org_project_pv_sys_typo_pv_system"
          )}
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            color: "#969696",
          }}
        >
          {myTranslator(
            translation,
            selectedLang,
            "org_project_pv_sys_typo_cha_the_se_of_pvsys"
          )}
        </Typography>
        <Box mt={3} component="form" autoComplete="off" onSubmit={handleForm}>
          <Box maxWidth="450px" mt={4}>
            <SelectBox
              labelTop="org_project_pv_sys_label_compensation_model"
              fullWidth
              items={pvOption}
              initValue={formData.pv_system}
              error={formErrors?.pv_system}
              name="pv_system"
              handleChange={handleInput}
            />
          </Box>
          {showUpdateBtn && (
            <SaveChangesBtn
              type="submit"
              cancel={() => setShowUpdateBtn(false)}
            />
          )}
        </Box>
      </Container>
      {isLoading && <MyLoader />}
    </>
  );
}

const pvOption = [
  { label: "org_project_pv_sys_pvoption_full_feed", value: "full_feed" },
  { label: "org_project_pv_sys_pvoption_own_consum", value: "own_consumption" },
  {
    label: "org_project_pv_sys_pvoption_own_cons_zev",
    value: "own_consumption_zev",
  },
];
