import React, { useState, Fragment } from "react";
import {
  Container,
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  InputField,
  PopUpMsg,
  SelectBox,
  PasswordInputField,
  LoadingButton,
} from "../../../components";
import { openPopUp } from "../../../store/reducer";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function AddOrganization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    telephone: "",
    mobile_phone: "",
    image: null,
    language: "english",
    role: "manager",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    type: "error",
    message: "",
  });

  const handleFile = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    let fd = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      fd.append(key, value);
    }
    try {
      await API("admin/organizations", "post", fd);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(
            translation,
            selectedLang,
            "sa_org__add_org_has_been_created"
          ),
          type: "success",
        })
      );
      navigate("/admin/organizations");
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        setOpen(true);
        setPayload({ message: err?.response?.message, type: "error" });
      }
      setIsLoading(false);
    }
  };

  var inputFields = [
    {
      placeholder: myTranslator(translation, selectedLang, "sa_org_add_title"),
      label: myTranslator(translation, selectedLang, "sa_org_add_title"),
      name: "title",
      value: "",
      error: formErrors.title,
      type: "text",
      required: true,
    },
    {
      placeholder: myTranslator(
        translation,
        selectedLang,
        "sa_org_add_description"
      ),
      name: "description",
      label: myTranslator(translation, selectedLang, "sa_org_add_description"),
      error: "",
      value: "",
      type: "text_area",
      required: true,
    },
    {
      placeholder: myTranslator(
        translation,
        selectedLang,
        "sa_org_add_first_name"
      ),
      label: myTranslator(translation, selectedLang, "sa_org_add_first_name"),
      name: "first_name",
      error: formErrors?.first_name,
      value: "",
      type: "text",
      required: true,
    },
    {
      placeholder: myTranslator(
        translation,
        selectedLang,
        "sa_org_add_last_name"
      ),
      label: myTranslator(translation, selectedLang, "sa_org_add_last_name"),
      name: "last_name",
      error: formErrors?.last_name,
      value: "",
      type: "text",
      required: true,
    },
    {
      placeholder: "Johndoe@gmail.com",
      label: myTranslator(translation, selectedLang, "sa_org_add_email"),
      name: "email",
      type: "email",
      error: formErrors?.email,
      value: "",
      required: true,
    },
    {
      placeholder: myTranslator(
        translation,
        selectedLang,
        "sa_org_add_telephone"
      ),
      label: myTranslator(translation, selectedLang, "sa_org_add_telephone"),
      name: "telephone",
      error: formErrors?.telephone,
      value: "",
      type: "tel",
      required: true,
    },
    {
      placeholder: myTranslator(
        translation,
        selectedLang,
        "sa_org_add_mobile_phone"
      ),
      label: myTranslator(translation, selectedLang, "sa_org_add_mobile_phone"),
      name: "mobile_phone",
      error: formErrors?.mobile_phone,
      value: "",
      type: "tel",
      required: false,
    },
    {
      placeholder: myTranslator(
        translation,
        selectedLang,
        "sa_org_add_language"
      ),
      label: myTranslator(translation, selectedLang, "sa_org_add_language"),
      name: "language",
      error: formErrors?.language,
      value: formData.language,
      type: "select_box",
      required: true,
    },
    {
      type: "file",
    },
    {
      placeholder: "******",
      label: myTranslator(translation, selectedLang, "sa_org_add_password"),
      name: "password",
      error: formErrors?.password,
      value: "",
      type: "password",
      required: true,
    },
  ];

  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Tooltip
        title={myTranslator(
          translation,
          selectedLang,
          "sa_org_add_tooltip_back"
        )}
        placement="top"
      >
        <IconButton onClick={() => navigate("/admin/organizations")}>
          <KeyboardReturnRoundedIcon />
        </IconButton>
      </Tooltip>
      <form autoComplete="off" onSubmit={handleForm}>
        <Box mt={2} mb={3}>
          <Typography variant="h6" component="h6" mb={1}>
            {myTranslator(translation, selectedLang, "sa_org_add_org_info")}
          </Typography>
          <Grid container spacing={1}>
            {inputFields.map((input, index) => {
              return (
                <Fragment key={index}>
                  {input.name === "first_name" && (
                    <Grid item md={12} sm={12} xs={12} key={index + "p"}>
                      <Typography variant="h6" component="h6" mb={1}>
                        {myTranslator(
                          translation,
                          selectedLang,
                          "sa_org_add_manager_info"
                        )}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item md={6} sm={12} xs={12} key={index}>
                    {input.type === "file" ? (
                      <InputField
                        type="file"
                        name="image"
                        error={formErrors?.image}
                        size="small"
                        fullWidth
                        styles={{ mb: 2 }}
                        handleChange={handleFile}
                        inputProps={{
                          accept: "image/png, image/jpeg",
                        }}
                      />
                    ) : input.type === "select_box" ? (
                      <SelectBox
                        items={langOptions}
                        initValue={input.value}
                        label={input.label}
                        name={input.name}
                        error={input.error}
                        size="small"
                        fullWidth
                        styles={{ mb: 2 }}
                        handleChange={handleChange}
                      />
                    ) : input.type === "password" ? (
                      <PasswordInputField
                        placeholder={input.placeholder}
                        label={input.label}
                        name={input.name}
                        required={input.required}
                        size="small"
                        fullWidth
                        styles={{ mb: 2 }}
                        handleChange={handleChange}
                      />
                    ) : (
                      <InputField
                        placeholder={input.placeholder}
                        label={input.label}
                        name={input.name}
                        error={input.error}
                        required={input.required}
                        type={input?.type || "text"}
                        size="small"
                        fullWidth
                        styles={{ mb: 2 }}
                        multiline={input.type === "text_area"}
                        handleChange={handleChange}
                      />
                    )}
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>

          <Box sx={{ textAlign: "end", mt: 2 }}>
            <Button
              size="small"
              sx={{
                mr: 1,
              }}
              onClick={() => navigate("/admin/organizations")}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              disabled={isLoading}
              type="submit"
              loading={isLoading}
              title="Create"
            />
          </Box>
        </Box>
      </form>
      <PopUpMsg
        open={!!open}
        type={payload.type}
        message={payload.message ?? "error occurred"}
        handleClose={() => setOpen(false)}
      />
    </Container>
  );
}

const langOptions = [
  { label: "Deutsch (CH)", value: "dutch" },
  { label: "English", value: "english" },
];
