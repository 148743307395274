import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  FormControlLabel,
  FormControl,
  Grid,
  Typography,
  Stack,
  RadioGroup,
  Radio,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import {
  InputField,
  MyLoader,
  InfoHeading,
  SaveChangesBtn,
} from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useParams } from "react-router-dom";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function Customer() {
  PageTitle("Customer");
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  const { id } = useParams();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    _method: "patch",
  });
  const [formErrors, setFormErrors] = useState({});
  const _userRole = useSelector((state) => state.storeReducer.user.role);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(`${_userRole}/projects/${id}`, "get");
      setIsLoading(false);
      setFormData({
        ...formData,
        customer_type: data?.customer_type || "",
        gender_type: data?.gender_type || "",
        first_name: data?.first_name || "",
        surname: data?.surname || "",
        email: data?.email || "",
        language: data?.language || "",
        street: data?.street || "",
        no: data?.no || "",
        postal_code: data?.postal_code || "",
        location: data?.location || "",
        land: data?.land || "",
        telephone: data?.telephone || "",
        phone_type: data?.phone_type || "",
        private_tel: data?.private_tel || "",
        mobile_phone: data?.mobile_phone || "",
        address: data?.address || "",
        project_template_id: Number(data?.project_template_id),
        organization_id: Number(data?.organizations?.id),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setShowUpdateBtn(true);
  };

  const handleRadio = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setShowUpdateBtn(true);
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setFormErrors({});
    let _fd = formData;
    setIsLoading(true);
    try {
      await API(`${_userRole}/projects/${id}`, "post", _fd);
      setShowUpdateBtn(false);
      dispatch(
        openPopUp({
          open: true,
          message: myTranslator(
            translation,
            selectedLang,
            "org_project_cus_alert_success"
          ),
          type: "success",
        })
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setFormErrors(errorsSetter(error));
      dispatch(
        openPopUp({
          open: true,
          message: myTranslator(
            translation,
            selectedLang,
            "org_project_cus_alert_error"
          ),
          type: "error",
        })
      );
    }
  };

  const _pattern = "[0-9]{11}";

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <Typography
          variant="h5"
          component="h5"
          fontSize={"18px"}
          mt={"12px"}
          mb={"10px"}
        >
          {myTranslator(
            translation,
            selectedLang,
            "org_project_cus_typo_customer"
          )}
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            color: "#969696",
          }}
        >
          {" "}
          {myTranslator(
            translation,
            selectedLang,
            "org_project_cus_typo_change_details"
          )}
        </Typography>
        <Box component="form" autoComplete="off" onSubmit={handleForm}>
          <Typography
            variant="subtitle1"
            component="h5"
            mt={2}
            sx={{
              color: "#464646",
            }}
          >
            {myTranslator(
              translation,
              selectedLang,
              "org_project_cus_typo_customer_type"
            )}
          </Typography>
          <FormControl>
            <RadioGroup row name="controlled-radio-buttons-group">
              <FormControlLabel
                value="private"
                control={
                  <Radio
                    checked={
                      formData.customer_type === "private" ? true : false
                    }
                    onChange={() => handleRadio("customer_type", "private")}
                    color="success"
                    size="small"
                  />
                }
                label={myTranslator(
                  translation,
                  selectedLang,
                  "org_project_cus_label_private"
                )}
              />
              <FormControlLabel
                value="business"
                control={
                  <Radio
                    checked={
                      formData.customer_type === "company" ? true : false
                    }
                    onChange={() => handleRadio("customer_type", "company")}
                    color="success"
                    size="small"
                  />
                }
                label={myTranslator(
                  translation,
                  selectedLang,
                  "org_project_cus_label_company"
                )}
              />
            </RadioGroup>
          </FormControl>
          <Typography
            variant="subtitle1"
            component="h5"
            mt={2}
            sx={{
              color: "#464646",
            }}
          >
            {myTranslator(
              translation,
              selectedLang,
              "org_project_cus_typo_gender"
            )}
          </Typography>
          <FormControl>
            <RadioGroup row name="controlled-radio-buttons-group">
              <FormControlLabel
                value="herr"
                control={
                  <Radio
                    checked={formData.gender_type === "mr" ? true : false}
                    onChange={() => handleRadio("gender_type", "mr")}
                    color="success"
                    size="small"
                  />
                }
                label={myTranslator(
                  translation,
                  selectedLang,
                  "org_project_cus_gender_male"
                )}
              />
              <FormControlLabel
                value="women"
                control={
                  <Radio
                    checked={formData.gender_type === "women" ? true : false}
                    onChange={() => handleRadio("gender_type", "women")}
                    color="success"
                    size="small"
                  />
                }
                label={myTranslator(
                    translation,
                    selectedLang,
                    "org_project_cus_gender_female"
                  )}
              />
              <FormControlLabel
                value="not_specified"
                control={
                  <Radio
                    checked={
                      formData.gender_type === "not_specified" ? true : false
                    }
                    onChange={() => handleRadio("gender_type", "not_specified")}
                    color="success"
                    size="small"
                  />
                }
                label={myTranslator(
                    translation,
                    selectedLang,
                    "org_project_cus_gender_not_specified"
                  )}
              />
            </RadioGroup>
          </FormControl>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <InputField
                    fullWidth
                    labelTop="org_project_cus_label_first_name"
                    required
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_first_name"
                      )}
                    size="small"
                    name="first_name"
                    initValue={formData.first_name}
                    error={formErrors?.first_name}
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <InputField
                    initValue={formData.surname}
                    error={formErrors?.surname}
                    fullWidth
                    labelTop="org_project_cus_label_surname"
                    required
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_surname"
                      )}
                    name="surname"
                    size="small"
                    handleChange={handleInput}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={3} sm={6} xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box sx={{ width: "100%" }}>
                      <InputField
                        initValue={formData.street}
                        error={formErrors?.street}
                        fullWidth
                        labelTop="org_project_cus_label_street"
                        placeholder="Street"
                        size="small"
                        name="street"
                        handleChange={handleInput}
                      />
                    </Box>
                    <Box sx={{ width: "100px" }}>
                      <InputField
                        initValue={formData.no}
                        error={formErrors?.no}
                        labelTop="org_project_cus_label_no"
                        type="number"
                        size="small"
                        name="no"
                        handleChange={handleInput}
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <InputField
                    initValue={formData.postal_code}
                    error={formErrors?.postal_code}
                    fullWidth
                    labelTop="org_project_cus_label_postal_code"
                    placeholder="Postal Code"
                    type="number"
                    size="small"
                    name="postal_code"
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <InputField
                    initValue={formData.location}
                    error={formErrors?.location}
                    fullWidth
                    labelTop="org_project_cus_label_location"
                    placeholder="Location"
                    size="small"
                    name="location"
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <InfoHeading text="org_project_cus_label_land" containerSx={{ mb: 1 }} />
                  <InputField
                    initValue={formData.land}
                    error={formErrors?.land}
                    fullWidth
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_land"
                      )}   
                    size="small"
                    name="land"
                    handleChange={handleInput}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <InputField
                    initValue={formData.email}
                    error={formErrors?.email}
                    fullWidth
                    labelTop="org_project_cus_label_email"
                    placeholder="doe@example.com"
                    type="email"
                    size="small"
                    name="email"
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <InputField
                    initValue={formData.private_tel}
                    error={formErrors?.private_tel}
                    fullWidth
                    labelTop="org_project_cus_label_private_telephone"
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_private_telephone"
                      )} 
                    type="tel"
                    size="small"
                    name="private_tel"
                    inputProps={{
                      pattern: _pattern,
                      title: "[098765432105]",
                    }}
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <InputField
                    initValue={formData.mobile_phone}
                    error={formErrors?.mobile_phone}
                    fullWidth
                    labelTop="org_project_cus_label_mobile_phone"
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_mobile_phone"
                      )} 
                    type="tel"
                    size="small"
                    name="mobile_phone"
                    inputProps={{
                      pattern: _pattern,
                      title: "[098765432105]",
                    }}
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <InputField
                    initValue={formData.telephone}
                    error={formErrors?.telephone}
                    fullWidth
                    labelTop="org_project_cus_label_telephone_store"
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_telephone_store"
                      )} 
                    type="tel"
                    size="small"
                    name="telephone"
                    inputProps={{
                      pattern: _pattern,
                      title: "[098765432105]",
                    }}
                    handleChange={handleInput}
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <InfoHeading
                    text="org_project_cus_label_building_address"
                    containerSx={{ mb: 1 }}
                  />
                  <InputField
                    initValue={formData?.address}
                    error={formErrors?.address}
                    fullWidth
                    placeholder={myTranslator(
                        translation,
                        selectedLang,
                        "org_project_cus_label_address"
                      )} 
                    size="small"
                    name="address"
                    handleChange={handleInput}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showUpdateBtn && (
            <SaveChangesBtn
              type="submit"
              cancel={() => setShowUpdateBtn(false)}
            />
          )}
        </Box>
      </Container>
      {isLoading && <MyLoader />}
    </>
  );
}
