import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import API from "../../../axios";
import {
  AddProjectBtn,
  TableWrapper,
  UsePagination,
} from "../../../components";
import CreateModel from "./create-model";
import ApiImage from "../../../hooks/fetch-image";
import { useNavigate } from "react-router-dom";
import myTranslator from "../../../helpers/myTranslator";
import { useSelector } from "react-redux";

var tableCols = [
  "sa_model_get_tb_title",
  "sa_model_get_tb_manufacturer",
  "sa_model_get_tb_picture",
  "sa_model_get_tb_status",
  "sa_model_get_tb_action",
];

export default function GetModels() {
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState(null);
  const [page, setPage] = useState(1);
  const [createModal, setCreateModal] = useState(false);
  const navigate = useNavigate();
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  useEffect(() => {
    getModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getModels = async () => {
    setIsLoading(true);
    try {
      const res = await API(`admin/modals?page=${page}`, "get");
      setModels(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setModels(null);
    }
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Typography variant="h6" mb={2}>
          {myTranslator(translation, selectedLang, "sa_model_get_heading")}
        </Typography>
        <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>
              {myTranslator(translation, selectedLang, column)}
            </TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={!!models && models?.data.length ? true : false}
          tableStyle={{ minWidth: "400px" }}
        >
          {!!models &&
            models?.data.map((model, index) => (
              <TableRow key={index}>
                <TableCell>{model.title}</TableCell>
                <TableCell>{model.manufacturers?.manufacturer}</TableCell>
                <TableCell>
                  <MyImage id={model.id} />
                </TableCell>
                <TableCell>
                  <Chip
                    label={
                      model.active
                        ? myTranslator(
                            translation,
                            selectedLang,
                            "sa_label_active"
                          )
                        : myTranslator(
                            translation,
                            selectedLang,
                            "sa_label_disabled"
                          )
                    }
                    color={model.active ? "success" : "error"}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={myTranslator(
                      translation,
                      selectedLang,
                      "sa_model_get_tooltip_edit"
                    )}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => {
                        navigate(
                          `/admin/manufacturer/${model.manufacturer_id}/model/${model.id}`,
                          {
                            id: model.id,
                            manufacturer_id: model.manufacturer_id,
                          }
                        );
                      }}
                      color="primary"
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>
        {!!models && models?.last_page > 1 && (
          <Box component="div" sx={{ mt: 2 }}>
            <UsePagination
              total={models?.total}
              perPage={models?.per_page}
              page={page}
              setPage={setPage}
            />
          </Box>
        )}
      </Container>
      {createModal && (
        <CreateModel
          handleClose={() => setCreateModal(false)}
          getData={getModels}
        />
      )}
      <AddProjectBtn
        handleClick={() => setCreateModal(true)}
        tooltip={myTranslator(
          translation,
          selectedLang,
          "sa_model_get_tooltip_create_model"
        )}
      />
    </>
  );
}

const MyImage = ({ id }) => {
  const image = ApiImage(`modal-image/${id}`);
  return (
    <a href={image} target="_blank" rel="noreferrer">
      <img
        alt="..."
        src={image}
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "100%",
        }}
      />
    </a>
  );
};
