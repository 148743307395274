import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  TableCell,
  Typography,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import qs from "qs";

import API from "../../../axios";
import {
  UsePagination,
  PopUpMsg,
  SelectBox,
  TableWrapper,
  InputField,
  MyLoader
} from "../../../components";
import TranslationForm from "./translation-form";
import TableRowComponent from "./table-row-component";
import { useDispatch, useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";
import { storeTranslation } from "../../../store/reducer";

const _mode = process.env.REACT_APP_MODE || "production";

export default function AdTranslation() {
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [records, setRecords] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [perPage, setPerPage] = useState(25);
  const [payload, setPayload] = useState({
    open: false,
    message: '',
    type: 'error'
  });
  const [IsFilterApply, setIsFilterApply] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const getData = async (flush = false) => {
    setIsLoading(true);
    let params;
    if (flush) {
      params = {
        page: 1,
        per_page: 25,
      }
    } else {
      params = {
        page: page,
        per_page: perPage,
        key: search,
        role: role
      }
    }
    try {
      const res = await API(`admin/translations?${qs.stringify(params)}`, "get")
      setRecords(res.data);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    };
  };

  const handleSubmission = () => {
    setShowForm(false);
  };

  const handlePublish = async () => {
    setLoader(true);
    try {
      await API('admin/refresh-files', "get");
      showMessage(myTranslator(translation, selectedLang, "sa_trans_get_alert_success"), 'success');
      getLang();
    } catch (error) {
      showMessage(myTranslator(translation, selectedLang, "sa_trans_get_alert_error"));
      setLoader(false);
    }
  };


  const getLang = async () => {
    try {
      const { data } = await API("get-csvfile?superadmin", "get");
      dispatch(storeTranslation(data));
    } finally {
      setLoader(false);
    }
  }

  const showMessage = (msg = '', type = 'error') => {
    setPayload({
      open: true,
      message: msg,
      type: type
    })
  }

  const handleUpload = async (e) => {
    let file = e.target.files[0];
    setLoader(true);
    try {
      let fd = new FormData();
      fd.append('file', file);
      await API('admin/upload-csv', "post", fd);
      getData();
      showMessage(`File ~ ${file.name} ${myTranslator(translation, selectedLang, "sa_trans_get_alert_uploaded_successfully")}`, 'success');
    } catch (error) {
      showMessage(`File ~ ${file.name} ${myTranslator(translation, selectedLang, "sa_trans_get_alert_uploaded_successfully")}`);
    } finally {
      setLoader(false);
    }
  };

  const applyFilters = () => {
    setPage(1)
    getData();
    setIsFilterApply(true);
  };

  const clearFilters = () => {
    setIsFilterApply(false);
    setPage(1)
    if (search !== "") {
      setSearch("");
    }
    if (role !== "") {
      setRole("");
    }
    getData(true);
  };

  const useFilters = () => {
    return (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems={{ xs: "center", md: "flex-end" }}
        spacing={1}
        flexWrap="wrap"
        sx={{
          "& > div,& > button": { mt: "10px !important" },
          "& > div": { width: { md: "fit-content !important", xs: "100% !important" } },
        }}
      >
        <InputField
          size="small"
          fullWidth
          label={myTranslator(translation, selectedLang, "sa_trans_get_label_search_by_key")}
          handleChange={(e) => setSearch(e.target.value)}
          initValue={search}
        />
        <SelectBox
          size="small"
          label={myTranslator(translation, selectedLang, "sa_trans_get_label_role")}
          required
          fullWidth
          initValue={role}
          handleChange={(e) => setRole(e.target.value)}
          items={dashType}
        />
        {(search || role) !== '' && (
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={applyFilters}
          >
            {myTranslator(translation, selectedLang, "sa_trans_get_btn_filter")}
          </Button>
        )}
        {IsFilterApply &&
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={clearFilters}
          >
            {myTranslator(translation, selectedLang, "sa_trans_get_btn_clear")}
          </Button>}
      </Stack>
    );
  };

  const myPagination = (st = {}) => {
    if (isLoading) { return }
    return records?.last_page > 1 && (
      <Box component="div" sx={st}>
        <UsePagination
          total={records?.total}
          perPage={records?.per_page}
          page={page}
          setPage={setPage}
          key={records?.last_page}
        />
      </Box>
    )
  }

  return (
    <Container maxWidth="100%" sx={{ py: 3, position: "relative" }}>
      <Typography variant="h6"> {myTranslator(translation, selectedLang, "sa_trans_get_typo_translation")}</Typography>
      <Box textAlign="end" mt={2} mb={2}>
        {_mode === "development" ? (
          <>
            <Button variant="contained" onClick={() => setShowForm(!showForm)}>
              {showForm ? myTranslator(translation, selectedLang, "sa_trans_get_btn_cancel") : myTranslator(translation, selectedLang, "sa_trans_get_btn_create")}
            </Button>{" "}
            <Button variant="contained" disabled={isLoading} onClick={getData}>
              {myTranslator(translation, selectedLang, "sa_trans_get_btn_refresh")}
            </Button>
          </>
        ) : null}
      </Box>
      <label htmlFor="filePicker" id='uploadCSV'>
        <input type='file' id='filePicker' onChange={handleUpload} hidden accept=".csv, text/csv" />
      </label>
      <Box>
        <Stack
          direction='row'
          alignItems="center"
          justifyContent='end'
          spacing={0.5}
        >
          <Tooltip title={myTranslator(translation, selectedLang, "sa_trans_get_tooltip_upload")} placement="top">
            <IconButton
              onClick={() => {
                document.getElementById('uploadCSV').click();
              }}
              size="large"
              color='primary'
            >
              <FileUploadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={myTranslator(translation, selectedLang, "sa_trans_get_tooltip_download")} placement="top">
            <a href={process.env.REACT_APP_API_KEY + `download-csv?${localStorage.getItem('@gamaLang')}`} rel="noreferrer" target='_blank' download>
              <IconButton
                size="large"
                color='primary'
              >
                <FileDownloadIcon />
              </IconButton>
            </a>
          </Tooltip>
          <Tooltip title={myTranslator(translation, selectedLang, "sa_trans_get_tooltip_publish")} placement="top">
            <IconButton
              onClick={handlePublish}
              size="large"
              color='primary'
            >
              <DownloadDoneIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
      {showForm && <TranslationForm handleSubmission={handleSubmission} />}
      <Box mt={3} mb={3}>
        {useFilters()}
      </Box>
      <Box mt={3} mb={3} maxWidth='200px'>
        <SelectBox
          size="small"
          label="Rows"
          required
          fullWidth
          initValue={perPage}
          handleChange={(e) => {
            setPerPage(e.target.value);
            setPage(1);
          }}
          items={rowsOpt}
        />
      </Box>
      {myPagination({ mb: 2 })}
      <TableWrapper
        thContent={
          <>
            <td></td>
            {thLabels.map(
              (v, i) => (
                <TableCell key={i}>{myTranslator(translation, selectedLang, v)}</TableCell>
              )
            )}
          </>
        }
        isLoading={isLoading}
        spanTd={thLabels.length + 1}
        isContent={records?.data?.length}
      >
        {records?.data.map((v, _) => (
          <TableRowComponent
            data={v}
            key={v.id}
            onDelete={() => {
              getData();
            }}
            getData={getData}
            setPayload={setPayload}
          />
        ))}
      </TableWrapper>
      {myPagination({ mt: 2 })}
      <PopUpMsg
        open={payload.open}
        type={payload.type}
        message={payload.message}
        handleClose={() => setPayload({ ...payload, open: false })}
      />
      {loader &&
        <MyLoader />
      }
    </Container>
  );
}

const dashType = [
  { label: "sa_trans_get_general", value: "general" },
  { label: "sa_trans_get_super_admin", value: "superadmin" },
  { label: "sa_trans_get_manager", value: "manager" },
  { label: "sa_trans_get_employee", value: "employee" },
]

const rowsOpt = [
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "75", value: 75 },
  { label: "100", value: 100 },
]

const thLabels = ["sa_trans_get_tb_key", "sa_trans_get_tb_english_(en)", "sa_trans_get_tb_deutsch_(ch)", "sa_trans_get_tb_other", "sa_trans_get_tb_action"];