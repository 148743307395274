import React, { useState, useEffect } from 'react'
import {
    Box,
    Container,
    FormControlLabel,
    Grid,
    Typography,
    Switch,
    InputAdornment,
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';


import PageTitle from '../../../hooks/page-title';
import API from '../../../axios';
import {
    InputField,
    MyLoader,
    SelectBox,
    SaveChangesBtn,
} from "../../../components";
import { openPopUp } from '../../../store/reducer';
import { useParams } from 'react-router-dom';
import { errorsSetter } from '../../../helpers/errors-setter';
import myTranslator from '../../../helpers/myTranslator';

export default function Building() {
    PageTitle('Building');
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [yearList, setYearList] = useState([]);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);

    const dispatch = useDispatch();

    const createYearList = () => {
        let list = []
        for (let i = 0; i < 50; i++) {
            let val = moment().add(-i, 'years').format('YYYY');
            list.push(
                { label: val, value: val }
            );
        }
        setYearList(list);
    }

    useEffect(() => {
        createYearList();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/projects/${id}`, 'get');
            setIsLoading(false);
            let _data = data?.building;
            let _flag = _data?.new_building ? 1 : 0;
            setFormData({
                ...formData,
                building_type: _data?.building_type || '',
                construction_year: _data?.construction_year || '',
                last_renovation: _data?.last_renovation || '',
                no_of_people: _data?.no_of_people || '',
                room_temperature: _data?.room_temperature || '',
                no_of_residential_unit: _data?.no_of_residential_unit || '',
                no_of_floor: _data?.no_of_floor || '',
                evergy_reference_area: _data?.evergy_reference_area || '',
                egid: _data?.egid || '',
                description: _data?.description || '',
                new_building: _flag,
                project_id: _data?.project_id || id,
            });
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleInput = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    }

    const handleForm = async (e) => {
        e.preventDefault();
        let _fd = { ...formData };
        setIsLoading(true);
        setFormErrors({});
        try {
            await API(`${_userRole}/buildings`, 'post', _fd);
            setShowUpdateBtn(false);
            dispatch(openPopUp({
                open: true,
                message: myTranslator(translation, selectedLang, "org_project_build_alert_success"),
                type: 'success'
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                open: true,
                message: myTranslator(translation, selectedLang, "org_project_build_alert_error"),
                type: 'error'
            }))
        }
    }

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Typography
                    variant="h5"
                    component="h5"
                    fontSize={"18px"}
                    mt={"12px"}
                    mb={"10px"}
                >
                    {myTranslator(translation, selectedLang, "org_project_build_typo_building")}
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                    }}
                >
                    {myTranslator(translation, selectedLang, "org_project_build_typo_building_settings")}
                </Typography>
                <Box
                    component='form'
                    autoComplete="off"
                    onSubmit={handleForm}
                    maxWidth='1100px'
                >
                    <Grid container spacing={3} mt={1}>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectBox
                                items={buildTypesOpt}
                                labelTop="org_project_build_label_building_type"
                                fullWidth
                                size="small"
                                initValue={formData?.building_type || ''}
                                error={formErrors?.building_type}
                                name='building_type'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectBox
                                items={yearList}
                                labelTop="org_project_build_label_construction_year"
                                fullWidth
                                size="small"
                                initValue={formData?.construction_year || ''}
                                error={formErrors?.construction_year}
                                required={formData?.last_renovation !== ''}
                                name='construction_year'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectBox
                                items={yearList}
                                labelTop="org_project_build_label_last_renovation"
                                fullWidth
                                size="small"
                                initValue={formData?.last_renovation || ''}
                                error={formErrors?.last_renovation}
                                name='last_renovation'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.no_of_people}
                                error={formErrors?.no_of_people}
                                fullWidth
                                labelTop="org_project_build_label_no_of_people"
                                placeholder="0"
                                type='number'
                                size="small"
                                name='no_of_people'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.room_temperature}
                                error={formErrors?.room_temperature}
                                fullWidth
                                labelTop="org_project_build_label_room_temp"
                                placeholder='0'
                                type='number'
                                size="small"
                                name='room_temperature'
                                handleChange={handleInput}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">°C</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.no_of_residential_unit}
                                error={formErrors?.no_of_residential_unit}
                                fullWidth
                                labelTop="org_project_build_label_no_of_res_units"
                                placeholder="0"
                                type='number'
                                size="small"
                                name='no_of_residential_unit'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.no_of_floor}
                                error={formErrors?.no_of_floor}
                                fullWidth
                                labelTop="org_project_build_label_no_of_floor"
                                placeholder="0"
                                type='number'
                                size="small"
                                name='no_of_floor'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.evergy_reference_area}
                                error={formErrors?.evergy_reference_area}
                                fullWidth
                                labelTop="org_project_build_label_evergy_ref_area"
                                placeholder='0'
                                type='number'
                                size="small"
                                name='evergy_reference_area'
                                handleChange={handleInput}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">m2</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography>
                                {myTranslator(translation, selectedLang, "org_project_build_typo_new_building")}
                            </Typography>
                            <FormControlLabel control={
                                <Switch
                                    onChange={(e) => {
                                        setFormData({ ...formData, new_building: e.target.checked ? 1 : 0 });
                                        setShowUpdateBtn(true);
                                    }}
                                    checked={formData.new_building === 1 ? true : false}
                                    color='success' />
                            }
                                sx={{ color: '#7d7d7d', '& .MuiTypography-body1': { fontSize: '0.85rem' } }}
                                label={myTranslator(translation, selectedLang, "org_project_build_label_if_activated")} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.egid}
                                        error={formErrors?.egid}
                                        fullWidth
                                        labelTop="org_project_build_label_egid"
                                        placeholder='0'
                                        type='number'
                                        size="small"
                                        name='egid'
                                        handleChange={handleInput}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* error caused by placeholder */}
                        <Grid item xs={12}>
                            <InputField
                                initValue={formData.description}
                                error={formErrors?.description}
                                fullWidth
                                labelTop="org_project_build_label_note_status_renovation"
                                placeholder={myTranslator(translation, selectedLang, "org_project_build_label_note_status_renovation")}
                                size="small"
                                name='description'
                                handleChange={handleInput}
                                multiline={true}
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                    {showUpdateBtn &&
                        <SaveChangesBtn type='submit' cancel={() => setShowUpdateBtn(false)} />}
                </Box>
            </Container>
            {isLoading &&
                <MyLoader />
            }
        </>
    )
}

const buildTypesOpt = [
    { label: 'org_project_build_label_detached_house', value: 'detached_house' },
    { label: 'org_project_build_label_apartment_building', value: 'apartment_building' },
    { label: 'org_project_build_label_office_building', value: 'office_building' },
    { label: 'org_project_build_label_industrial_building', value: 'industrial_building' },
    { label: 'org_project_build_label_town_house', value: 'town_house' },
];