import React, { useEffect, useState } from "react";

import {
    Typography,
    Grid,
    Box,
    Stack,
    Tooltip,
    IconButton
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import API from "../../../axios";
import {
    InputField,
    SelectBox,
    PasswordInputField,
    LoadingButton,
    MyLoader,
} from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";
import UserLayout from "./user-layout";

export default function UserRegForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);
    const { organization_id } = useSelector((state) => state.storeReducer.user);
    const [formData, setFormData] = useState({
        role: 'employee',
        language: 'dutch',
        status: 'pending',
    });
    const [record, setRecord] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!!id) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getData = async (e) => {
        setLoader(true);
        try {
            const { data } = await API(`manager/users/${id}`, "get");
            setRecord(data);
            setFormData({
                ...formData,
                first_name: data?.first_name || '',
                last_name: data?.last_name || '',
                telephone: data?.telephone || '',
                mobile_phone: data?.mobile_phone || '',
                language: data?.language || 'dutch',
                email: data?.email || '',
                role: data?.role || 'employee',
                status: data?.status,
            })
        } catch (err) {
            dispatch(openPopUp({ message: myTranslator(translation, selectedLang, "org_users_reg_form_alert_error_occurred"), type: "error" }));
            setTimeout(() => {
                navigate('/users');
            }, 2000);
        } finally {
            setLoader(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitch = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }

    const handleForm = async (e) => {
        e.preventDefault();
        let _data = {...formData}
        if (!id) {
            _data.organization_id = Number(organization_id);
        }
        setIsLoading(true);
        setFormErrors({});
        try {
            let url = !!id ? `manager/update/user/${id}` : 'manager/users';
            await API(url, "post", _data);
            if (!id) {
                dispatch(openPopUp({ message: myTranslator(translation, selectedLang, "org_users_reg_form_alert_user_created"), type: "success" }));
            } else {
                dispatch(openPopUp({ message: myTranslator(translation, selectedLang, "org_users_reg_form_alert_user_updated"), type: "success" }));
            }
            navigate('/users')
        } catch (err) {
            if (err?.response?.status === 422) {
                setFormErrors(errorsSetter(err));
            } else {
                dispatch(
                    openPopUp({ message: err?.response?.data?.message, type: "error" })
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <UserLayout>
            <Box
                component="form"
                onSubmit={handleForm}
                autoComplete="off"
                sx={{ mx: 'auto', maxWidth: "850px", mt: 2 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction='row' alignItems={{ sm: 'center', sx: 'flex-start' }} spacing={1}>
                            <Tooltip title={myTranslator(translation, selectedLang, "org_tooltip_back")} placement="top">
                                <IconButton onClick={() => navigate('/users')}>
                                    <KeyboardReturnRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6">
                                {!!id ?
                                    loader ? '---' : (record?.first_name + ' ' + record?.last_name)
                                    : myTranslator(translation, selectedLang, "org_users_reg_form_typo_user_reg_form")}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder={myTranslator(translation, selectedLang, "org_users_reg_form_label_first_name")}
                            size="small"
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_first_name")}
                            fullWidth
                            required
                            initValue={formData?.first_name}
                            error={formErrors?.first_name}
                            name="first_name"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder={myTranslator(translation, selectedLang, "org_users_reg_form_label_last ")}
                            size="small"
                            fullWidth
                            initValue={formData?.last_name}
                            labelTop="last name"
                            error={formErrors?.last_name}
                            name="last_name"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder="0987654321"
                            size="small"
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_mobile_phone")}
                            fullWidth
                            inputProps={{
                                pattern: ".{10,20}", title: "[03339876543]"
                            }}
                            initValue={formData?.mobile_phone}
                            error={formErrors?.mobile_phone}
                            name="mobile_phone"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder="0987654321"
                            size="small"
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_telephone")}
                            fullWidth
                            required
                            initValue={formData?.telephone}
                            inputProps={{
                                pattern: ".{10,20}", title: "[03339876543]"
                            }}
                            error={formErrors?.telephone}
                            name="telephone"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <SelectBox
                            items={[
                                { label: "English", value: "english" },
                                { label: "Deutsch (ch)", value: "dutch" },
                            ]}
                            initValue={formData?.language}
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_language")}
                            size="small"
                            name="language"
                            fullWidth
                            required
                            error={formErrors?.language}
                            handleChange={(event) => handleChange(event)}
                            styles={{ minWidth: "185px" }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <SelectBox
                            items={[
                                { label: "org_users_reg_form_label_manager", value: "manager" },
                                { label: "org_users_reg_form_label_employee", value: "employee" },
                                { label: "org_users_reg_form_label_stock_manager", value: "stock_manager" },
                                { label: "org_users_reg_form_label_plumber", value: "plumber" },
                            ]}
                            size="small"
                            fullWidth
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_role")}
                            name="role"
                            required
                            initValue={formData?.role}
                            error={formErrors?.role}
                            handleChange={(item) => handleChange(item)}
                            styles={{ minWidth: "185px" }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder="Johndoe@gmail.com"
                            size="small"
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_email")}
                            fullWidth
                            required={Boolean(!id)}
                            disabled={Boolean(!!id)}
                            initValue={formData?.email}
                            error={formErrors?.email}
                            name="email"
                            type="email"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <PasswordInputField
                            placeholder="xxxxxx"
                            labelTop={myTranslator(translation, selectedLang, "org_users_reg_form_label_password")}
                            size="small"
                            fullWidth
                            inputProps={{
                                pattern: ".{6,}", title: "6 or more characters"
                            }}
                            name="password"
                            error={formErrors?.password}
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <SelectBox
                            items={[
                                { label: "Pending", value: "pending" },
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                            size="small"
                            fullWidth
                            labelTop={myTranslator(translation, selectedLang, "Status")}
                            name="status"
                            required
                            initValue={formData?.status}
                            error={formErrors?.status}
                            handleChange={(item) => handleChange(item)}
                            styles={{ minWidth: "185px" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ textAlign: "end", mt: 2 }}>
                            <LoadingButton
                                variant="contained"
                                disabled={isLoading}
                                type="submit"
                                loading={isLoading}
                                title="Submit" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {loader &&
                <MyLoader />
            }
        </UserLayout>
    );
}
