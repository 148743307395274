import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  Container,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";

import { CustomModal, InputField, LoadingButton, SearchComboBox } from "../../../components";
import API from "../../../axios";
import myTranslator from "../../../helpers/myTranslator";

export default function CreateModel({ handleClose, getData = () => { } }) {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    title: "",
    manufacturer: "",
    manufacturerId: 0,
    image: "",
    modal_price: 0,
    calculation_surcharge: "",
    installation_cost: "",
    selling_price: "",
    price_level: "",
    cost_price: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();

  const handleForm = async (e) => {
    const fd = new FormData();
    fd.append("image", formData.image[0]);
    fd.append("title", formData.title);
    fd.append("modal_price", formData.modal_price);
    if (formData.modal_price === 1) {
      fd.append("calculation_surcharge", formData.calculation_surcharge);
      fd.append("installation_cost", formData.installation_cost);
      fd.append("selling_price", formData.selling_price);
      fd.append("price_level", formData.price_level);
      fd.append("cost_price", formData.cost_price);
    }
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(
        `admin/manufacturers/${formData.manufacturerId}/modals`,
        "post",
        fd
      );
      setIsLoading(false);
      let obj = {
        message: "Model has been created.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(err);
      } else {
        let obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              {myTranslator(translation, selectedLang, "sa_model_create_heading")}
            </Typography>
          </Box>
          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              <InputField
                placeholder={myTranslator(translation, selectedLang, "sa_model_create_label_title")}
                size="small"
                label={myTranslator(translation, selectedLang, "sa_model_create_label_title")}
                required
                fullWidth
                styles={{ mb: 2 }}
                name="title"
                error={formErrors?.title}
                handleChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
              <SearchComboBox
                initValue={formData.manufacturer}
                url={"admin/manufacturers?active=active&"}
                fullWidth
                required={true}
                objLabel='manufacturer'
                error={formErrors?.manufacturer}
                size="small"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    manufacturer: e.manufacturer,
                    manufacturerId: e.id,
                  });
                }}
                label={myTranslator(translation, selectedLang, "sa_model_create_label_manufacturer")}
              />

              <FormControl variant="standard">
                <Typography component="label" variant="label" mt={2}>
                  {myTranslator(translation, selectedLang, "sa_model_create_label_select_image")}{" "}
                </Typography>
                <input
                  required
                  name="image"
                  onChange={(e) =>
                    setFormData({ ...formData, image: e.target.files })
                  }
                  type="file"
                  accept="image/*"
                />
              </FormControl>
              {formErrors?.image !== "" && (
                <FormHelperText sx={{ color: "red", mt: "0 !important" }}>
                  {formErrors?.image}
                </FormHelperText>
              )}
              <Box sx={{ textAlign: "end", mt: 2 }}>
                <Button
                  onClick={() => handleClose(false)}
                  sx={{
                    mr: 1,
                  }}
                  type="button"
                >
                  {myTranslator(translation, selectedLang, "sa_model_create_btn_cancel")}
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  loading={isLoading}
                  title='sa_model_create_btn_create'
                />
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>
    </>
  );
}
