import React, { useState } from 'react';
import {
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    Stack,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import API from '../../../../axios';
import { InfoHeading, InputField, LoadingButton } from "../../../../components";
import { openPopUp } from '../../../../store/reducer';
import { errorsSetter } from '../../../../helpers/errors-setter';
import myTranslator from '../../../../helpers/myTranslator';


function TemplateForm({ handleClose }) {
    const [isLoading, setIsLoading] = useState(false);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        battery_storage: 0,
        energy_management: 0,
        photovoltaic: 0,
    });
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        var _data = {};
        _data = {
            title: formData.title,
            description: formData.description ?? '',
            battery_storage: formData.battery_storage ? '1' : '0',
            energy_management: formData.energy_management ? '1' : '0',
            photovoltaic: formData.photovoltaic ? '1' : '0',
        }
        setFormErrors({});
        try {
            let { data } = await API(`${_userRole}/project-templates`, 'post', _data);
            dispatch(openPopUp({
                message: myTranslator(translation, selectedLang, "org_lib_create_temp_alert_success"),
                type: 'success'
            }));
            setIsLoading(false);
            navigate(`/library/project-template/${data.id}`, { id: data.id })
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                message: myTranslator(translation, selectedLang, "org_lib_create_temp_alert_error"),
                type: 'error'
            }));
        }
    }

    return (
        <>
            <Box
                component='form'
                autoCapitalize="off"
                autoComplete="off"
                onSubmit={handleForm}
                sx={{
                    pb: 4,
                    px: 1
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="h5">
                            {myTranslator(translation, selectedLang, "org_lib_create_temp_typo_top")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            fullWidth
                            required
                            labelTop="org_lib_label_project_temp_name"
                            name='title'
                            error={formErrors?.title}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            fullWidth
                            labelTop="org_lib_label_description"
                            name='description'
                            multiline
                            rows="4"
                            error={formErrors?.description}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InfoHeading
                            text="org_lib_info_heading"
                            variant='h6'
                        />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.energy_management)} name='energy_management' onChange={handleSwitchChange} size='small' />} label={myTranslator(translation, selectedLang, "org_lib_energy_management")} />
                            <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.battery_storage)} name='battery_storage' onChange={handleSwitchChange} size='small' />} label={myTranslator(translation, selectedLang, "org_lib_label_battery_storage")} />
                            <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.photovoltaic)} name='photovoltaic' onChange={handleSwitchChange} size='small' />} label={myTranslator(translation, selectedLang, "org_lib_label_photovoltaic")} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction='row'
                            spacing={1}
                        >
                            <LoadingButton
                                variant="contained"
                                onClick={() => { }}
                                disabled={isLoading}
                                type="submit"
                                loading={isLoading}
                                title="org_lib_btn_add_to"
                            />
                            <Button
                                type="button"
                                variant="contained"
                                color='error'
                                onClick={handleClose}
                            >
                                {myTranslator(translation, selectedLang, "org_lib_btn_abort")}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
TemplateForm.defaultProps = {
    handleClose: () => { }
}
export default TemplateForm;