import React, { useState, useEffect } from "react";

import {
  Box,
  TableRow,
  TableCell,
  CircularProgress,
  Popper,
  Button,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';

import API from "../../../axios";
import { InputField } from "../../../components";
import { errorsSetter } from "../../../helpers/errors-setter";
import { useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";


const _mode = process.env.REACT_APP_MODE || "production";

export default function TableRowComponent({ data = {}, setPayload = () => { }, onDelete = () => { }, getData = () => { } }) {
  const [formData, setFormData] = useState({});
  const [editable, setEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [record, setRecord] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const openPopper = Boolean(anchorEl);
  const popperID = openPopper ? 'simple-popper' : undefined;

  useEffect(() => {
    if (data !== null) {
      setFormData({
        key: data.key ?? "",
        english: data.english ?? "",
        german: data.german ?? "",
        other: data.other ?? "",
      });
      setRecord(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updateRow = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let url = `admin/translation/${data.id}`;
    setFormErrors({});
    try {
      await API(url, "post", formData);
      setPayload({
        open: true,
        message: myTranslator(translation, selectedLang, "sa_trans_row_alert_success"),
        type: "success"
      })
      setRecord({ ...formData });
      setEditable(false);
    } catch (err) {
      errorsSetter(setFormErrors(err));
      setPayload({
        open: true,
        message: myTranslator(translation, selectedLang, "sa_trans_row_alert_error"),
        type: "error"
      })
    } finally {
      setIsLoading(false);
    }
  };

  const deleteRow = async () => {
    setIsLoading(true);
    let url = `admin/delete-translation/${data.id}`;
    try {
      await API(url, "delete", formData)
      setIsLoading(false);
      setPayload({
        open: true,
        message: myTranslator(translation, selectedLang, "sa_trans_row_alert_key_removed"),
        type: "success"
      })
      onDelete();
    }
    catch (err) {
      setIsLoading(false);
      setFormErrors(errorsSetter(err));
      setPayload({
        open: true,
        message: myTranslator(translation, selectedLang, "sa_trans_row_alert_error_occurred"),
        type: "error"
      })
    };
  };

  const cancelChanges = () => {
    setEditable(false);
    setFormData({
      key: record?.key ?? "",
      english: record?.english ?? "",
      german: record?.german ?? "",
      other: record?.other ?? "",
    });
  }

  return (
    <>
      <TableRow>
        <td>
          <form id={`form${data.id}`} onSubmit={updateRow}><input type="hidden" name="id" value="1" /></form>
        </td>
        {_mode === 'production' ? <TableCell sx={{ verticalAlign: "top" }}>{formData.key}</TableCell> :
          <TableCell sx={{ verticalAlign: "top" }}>
            {editable ?
              <InputField
                initValue={formData.key}
                placeholder={myTranslator(translation, selectedLang, "a_trans_row_placeholder_type")}
                multiline
                name="key"
                fullWidth
                required
                error={formErrors.key}
                label={myTranslator(translation, selectedLang, "sa_trans_row_label_key")}
                size="small"
                handleChange={handleChange}
                style={{
                  fontSize: "14px !important",
                }}
                inputProps={{
                  form: `form${data.id}`
                }}
              />
              :
              <p>{record?.key}</p>
            }
          </TableCell>}
        <TableCell sx={{ verticalAlign: "top" }}>
          {editable ?
            <InputField
              initValue={formData.english}
              placeholder={myTranslator(translation, selectedLang, "a_trans_row_placeholder_type")}
              multiline
              name="english"
              fullWidth
              required
              error={formErrors.english}
              label={myTranslator(translation, selectedLang, "sa_trans_row_label_english_(en)")}
              size="small"
              handleChange={handleChange}
              style={{
                fontSize: "14px !important",
              }}
              inputProps={{
                form: `form${data.id}`
              }}
            />
            :
            <p>{record?.english}</p>
          }
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          {editable ?
            <InputField
              placeholder={myTranslator(translation, selectedLang, "sa_trans_row_placeholder_type")}
              initValue={formData.german}
              multiline
              label={myTranslator(translation, selectedLang, "sa_trans_row_label_deutsch_(ch)")}
              error={formErrors.german}
              name="german"
              fullWidth
              size="small"
              handleChange={handleChange}
              inputProps={{
                form: `form${data.id}`
              }}
            />
            :
            <p>{record?.german || '-'}</p>
          }
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          {editable ?
            <InputField
              initValue={formData.other}
              multiline
              placeholder={myTranslator(translation, selectedLang, "sa_trans_row_placeholder_type")}
              label={myTranslator(translation, selectedLang, "sa_trans_row_label_other")}
              name="other"
              error={formErrors.other}
              fullWidth
              size="small"
              handleChange={handleChange}
              inputProps={{
                form: `form${data.id}`
              }}
            />
            :
            <p>{record?.other || '-'}</p>
          }
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            {editable ?
              <>
                <Tooltip title={myTranslator(translation, selectedLang, "sa_trans_row_tooltip_save")} placement="top">
                  <IconButton
                    type="submit"
                    color='success'
                    form={`form${data.id}`}
                  >
                    {isLoading ?
                      <CircularProgress color='success' size={22} />
                      :
                      <CheckRoundedIcon />
                    }
                  </IconButton>
                </Tooltip>
                <Tooltip title={myTranslator(translation, selectedLang, "sa_trans_row_toltip_cancel_changes")} placement="top">
                  <IconButton
                    onClick={cancelChanges}
                    color='error'
                    disabled={isLoading}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
              :
              <Tooltip title={myTranslator(translation, selectedLang, "sa_trans_row_tooltip_edit")} placement="top">
                <IconButton
                  onClick={() => setEditable(true)}
                  color='info'
                >
                  <CreateRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            {_mode === "development" ? (
              <IconButton
                onClick={handleClick}
                disabled={isLoading}
                type="button"
                color="error"
              >
                {isLoading ?
                  <CircularProgress color='error' size={22} />
                  :
                  <DeleteForeverIcon />
                }
              </IconButton>
            ) : null}
          </Stack>
          <Popper id={popperID} open={openPopper} placement='left' anchorEl={anchorEl}>
            <Box sx={modalSt}>
              <small>{myTranslator(translation, selectedLang, "sa_trans_row_typo_are_you_sure")}</small>
              <Button color="info" size="small" px={0} onClick={deleteRow}>{myTranslator(translation, selectedLang, "sa_trans_row_btn_yes")}</Button>
              <Button color="error" size="small" px={0} onClick={handleClick}>{myTranslator(translation, selectedLang, "sa_trans_row_btn_no")}</Button>
            </Box>
          </Popper>
        </TableCell>
      </TableRow>
    </>
  );
}
const modalSt = {
  border: '1px solid #ccc',
  p: 1,
  borderRadius: '7px',
  bgcolor: '#fff',
  '& .MuiButtonBase-root': {
    minWidth: 'fit-content',
    ml: 0.5
  }
}