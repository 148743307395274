import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
    Box,
    Button,
    CircularProgress,
    Container,
    Typography,
} from "@mui/material";
import { InputField } from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function FormModal({
    handleClose,
    record = null,
    formType = 'SUPPLIER',
    formMode = 'ADD',
    id = null,
    setEngSupplier = e => { },
    setTariff = e => { },
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState();
    const [formErrors, setFormErrors] = React.useState({});
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (formMode !== 'ADD') {
            setTitle(record.title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setFormErrors({});
        let _url = formType === 'SUPPLIER' ? 'admin/energy-suppliers' : 'admin/tariffs';
        let _data = {
            title: title,
        };
        if (formMode !== 'ADD' && formType === 'SUPPLIER') {
            _data = { ..._data, id: record.id }
        }
        if (formMode !== 'ADD' && formType !== 'SUPPLIER') {
            _data = { ..._data, tariff_id: record.id }
        }
        if (formType !== 'SUPPLIER') {
            _data = { ..._data, energy_supp_id: id }
        }
        try {
            let { data } = await API(_url, "post", _data);
            if (formType === 'SUPPLIER') {
                setEngSupplier(data);
            } else {
                setTariff(data);
            }
            setIsLoading(false);
            if (formType === 'ADD') {
                dispatch(
                    openPopUp({
                        message: formType === 'SUPPLIER' ? myTranslator(translation, selectedLang, "sa_tariffs_form_mod_alert_energy_supp") : myTranslator(translation, selectedLang, "sa_tariffs_form_mod_alert_tariff_created"),
                        type: "success",
                    })
                );
            } else {
                dispatch(
                    openPopUp({
                        message: formType === 'SUPPLIER' ? myTranslator(translation, selectedLang, "sa_tariffs_form_mod_alert_energy_supp_updated") : myTranslator(translation, selectedLang, "sa_tariffs_form_mod_alert_tariff_updated"),
                        type: "success",
                    })
                );
            }
            handleClose();
        } catch (err) {
            setFormErrors(errorsSetter(err));
            dispatch(
                openPopUp({ message: err?.response?.data?.message, type: "error" })
            );
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="100%">
            <Box>
                <Typography component="h6" variant="h6" textAlign="">
                    {formMode === 'ADD' && formType === 'SUPPLIER' ?
                        myTranslator(translation, selectedLang, "sa_tariffs_form_mod_typo_create_energy_supp")
                        : formMode !== 'ADD' && formType === 'SUPPLIER' ?
                            myTranslator(translation, selectedLang, "sa_tariffs_form_mod_typo_update_energy_supplier")
                            : formMode === 'ADD' && formType !== 'SUPPLIER' ?
                                myTranslator(translation, selectedLang, "sa_tariffs_form_mod_typo_create_tariff") : ' '
                    }
                </Typography>
            </Box>
            <form autoComplete="off" onSubmit={handleForm}>
                <Box mt={2} mb={3}>
                    <InputField
                        placeholder="Title"
                        size="small"
                        label={myTranslator(translation, selectedLang, "sa_tariffs_form_mod_label_title")}
                        initValue={title}
                        required
                        fullWidth
                        styles={{ mb: 2 }}
                        name="title"
                        error={formErrors.title}
                        handleChange={(e) => setTitle(e.target.value)}
                    />
                    <Box sx={{ textAlign: "end" }}>
                        <Button
                            onClick={handleClose}
                            sx={{
                                mt: 2,
                                mr: 1,
                            }}
                            type="button"
                        >
                            {myTranslator(translation, selectedLang, "sa_tariffs_form_mod_btn_cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => { }}
                            sx={{
                                mt: 2,
                            }}
                            disabled={isLoading}
                            type="submit"
                        >
                            {isLoading ? (
                                <CircularProgress
                                    size={18}
                                    sx={{ color: "#fff", mr: 1, size: "12px" }}
                                />
                            ) : null}
                            {formMode === 'ADD' ? myTranslator(translation, selectedLang, "sa_tariffs_form_mod_btn_create") : myTranslator(translation, selectedLang, "sa_tariffs_form_mod_btn_update")}
                        </Button>
                    </Box>
                </Box>
            </form>
        </Container>
    );
}
