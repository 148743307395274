import React, { useState } from "react";
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper
} from "@mui/material";

import CountUp from 'react-countup';
import PageTitle from "../../../hooks/page-title";
import { Header } from "../../../components";
import API from "../../../axios";
import { useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";
import { Link } from "react-router-dom";


export default function Home() {
    PageTitle('Home');
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState(null);
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/home`, 'get');
            setRecord(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Header hideMenu />
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Grid container spacing={2}>
                    {_userRole === 'manager' && <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title={myTranslator(translation, selectedLang, "org_home_title_employees")} count={isLoading ? 0 : record?.employees} url='/users' />
                    </Grid>}
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title={myTranslator(translation, selectedLang, "org_home_title_projects")} count={isLoading ? 0 : record?.projects} url='/projects' />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title={myTranslator(translation, selectedLang, "org_home_title_active_projects")} count={isLoading ? 0 : record?.activeProjects} url='/projects' />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title={myTranslator(translation, selectedLang, "org_home_title_complete_projects")} count={isLoading ? 0 : record?.completeProjects} url='/projects' />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title={myTranslator(translation, selectedLang, "org_home_title_cancel_projects")} count={isLoading ? 0 : record?.cancelProjects} url='/projects' />
                    </Grid>
                    {_userRole === 'manager' && <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title={myTranslator(translation, selectedLang, "org_home_title_components")} count={isLoading ? 0 : record?.components} url='/library/components' />
                    </Grid>}
                </Grid>
            </Container>
        </>
    )
}

const Card = ({ title = 'title', count = 0, url = '/' }) => {
    return (
        <Link to={url} style={{ textDecoration: 'none' }}>
            <Box component={Paper}
                sx={{ p: 3 }}
            >
                <Typography variant="h6">
                    {title}
                </Typography>
                <br />
                <Box sx={{ textAlign: 'end' }}>
                    <CountUp
                        duration={0.5}
                        end={count} />
                </Box>
            </Box>
        </Link>
    );
}