import React, { useEffect, useState } from "react";

import {
    Typography,
    Grid,
    Box,
    Stack,
    Tooltip,
    IconButton,
    Container
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import API from "../../../axios";
import {
    InputField,
    SelectBox,
    PasswordInputField,
    LoadingButton,
    MyLoader,
} from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { errorsSetter } from "../../../helpers/errors-setter";

export default function AdUserRegForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        role: 'employee',
        language: 'dutch',
        status: 'pending',
    });
    const [record, setRecord] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, user_id } = useParams();

    useEffect(() => {
        if (!!user_id) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getData = async (e) => {
        setLoader(true);
        try {
            const { data } = await API(`admin/user/${user_id}`, "get");
            setRecord(data);
            setFormData({
                ...formData,
                first_name: data?.first_name || '',
                last_name: data?.last_name || '',
                telephone: data?.telephone || '',
                mobile_phone: data?.mobile_phone || '',
                language: data?.language || 'dutch',
                email: data?.email || '',
                role: data?.role || 'employee',
                status: data?.status,
            })
        } catch (err) {
            dispatch(openPopUp({ message: "User not found.", type: "error" }));
            navigate(`/admin/organizations/${id}/users`)
        } finally {
            setLoader(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitch = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }

    const handleForm = async (e) => {
        e.preventDefault();
        let _data = {...formData}
        if (!user_id) {
            _data.organization_id = id;
        }
        setIsLoading(true);
        setFormErrors({});
        try {
            let url = !!user_id ? `admin/update/user/${user_id}` : 'admin/store/userbyadmin';
            await API(url, "post", _data);
            if (!user_id) {
                dispatch(openPopUp({ message: "User has been created.", type: "success" }));
            } else {
                dispatch(openPopUp({ message: "User has been updated.", type: "success" }));
            }
            navigate(`/admin/organizations/${id}/users`)
        } catch (err) {
            if (err?.response?.status === 422) {
                setFormErrors(errorsSetter(err));
            } else {
                dispatch(
                    openPopUp({ message: err?.response?.data?.message, type: "error" })
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <Box
                component="form"
                onSubmit={handleForm}
                autoComplete="off"
                sx={{ mx: 'auto', maxWidth: "850px", mt: 2 }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction='row' alignItems={{ sm: 'center', sx: 'flex-start' }} spacing={1}>
                            <Tooltip title='Back' placement="top">
                                <IconButton onClick={() => navigate(`/admin/organizations/${id}/users`)}>
                                    <KeyboardReturnRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6">
                                {!!user_id ?
                                    loader ? '---' : (record?.first_name + ' ' + record?.last_name)
                                    : 'User Registration Form'}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder='First Name'
                            size="small"
                            labelTop='First Name'
                            fullWidth
                            required
                            initValue={formData?.first_name}
                            error={formErrors?.first_name}
                            name="first_name"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder='Last Name'
                            size="small"
                            fullWidth
                            initValue={formData?.last_name}
                            labelTop="Last Name"
                            error={formErrors?.last_name}
                            name="last_name"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder="0987654321"
                            size="small"
                            labelTop='Mobile Phone'
                            fullWidth
                            inputProps={{
                                pattern: ".{10,20}", title: "[03339876543]"
                            }}
                            initValue={formData?.mobile_phone}
                            error={formErrors?.mobile_phone}
                            name="mobile_phone"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder="0987654321"
                            size="small"
                            labelTop='Telephone'
                            fullWidth
                            required
                            initValue={formData?.telephone}
                            inputProps={{
                                pattern: ".{10,20}", title: "[03339876543]"
                            }}
                            error={formErrors?.telephone}
                            name="telephone"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <SelectBox
                            items={[
                                { label: "English", value: "english" },
                                { label: "Deutsch (ch)", value: "dutch" },
                            ]}
                            initValue={formData?.language}
                            labelTop='Language'
                            size="small"
                            name="language"
                            fullWidth
                            required
                            error={formErrors?.language}
                            handleChange={(event) => handleChange(event)}
                            styles={{ minWidth: "185px" }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <SelectBox
                            items={[
                                { label: "org_users_reg_form_label_manager", value: "manager" },
                                { label: "org_users_reg_form_label_employee", value: "employee" },
                                { label: "org_users_reg_form_label_stock_manager", value: "stock_manager" },
                                { label: "org_users_reg_form_label_plumber", value: "plumber" },
                            ]}
                            size="small"
                            fullWidth
                            labelTop='Role'
                            name="role"
                            required
                            initValue={formData?.role}
                            error={formErrors?.role}
                            handleChange={(item) => handleChange(item)}
                            styles={{ minWidth: "185px" }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <InputField
                            placeholder="Johndoe@gmail.com"
                            size="small"
                            labelTop='Email'
                            fullWidth
                            required={Boolean(!user_id)}
                            disabled={Boolean(!!user_id)}
                            initValue={formData?.email}
                            error={formErrors?.email}
                            name="email"
                            type="email"
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <PasswordInputField
                            placeholder="xxxxxx"
                            labelTop='Password'
                            size="small"
                            fullWidth
                            inputProps={{
                                pattern: ".{6,}", title: "6 or more characters"
                            }}
                            name="password"
                            error={formErrors?.password}
                            handleChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <SelectBox
                            items={[
                                { label: "Pending", value: "pending" },
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                            size="small"
                            fullWidth
                            labelTop="Status"
                            name="status"
                            required
                            initValue={formData?.status}
                            error={formErrors?.status}
                            handleChange={(item) => handleChange(item)}
                            styles={{ minWidth: "185px" }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ textAlign: "end", mt: 2 }}>
                            <LoadingButton
                                variant="contained"
                                disabled={isLoading}
                                type="submit"
                                loading={isLoading}
                                title="Submit" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {loader &&
                <MyLoader />
            }
        </Container>
    );
}
