import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Stack,
  Popper,
  Box,
} from "@mui/material";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import API from "../../../axios";
import { openPopUp } from "../../../store/reducer";
import { InputField, SelectBox } from "../../../components";
import { errorsSetter } from "../../../helpers/errors-setter";
import { useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";

const GroupTableRow = ({
  item,
  index,
  modalId,
  groups_,
  addHandle = () => {},
  afterSubmit = (e, i) => {},
  removeRow = () => {},
}) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [editable, setEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Modal Setup
  const [open, setOpen] = React.useState(false);
  const [group, setGroup] = React.useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [rowID, setRowID] = useState(item.id);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openPopper = Boolean(anchorEl);
  const popperID = openPopper ? "simple-popper" : undefined;

  React.useEffect(() => {
    if (item?.editable) {
      setEditable(true);
      setIsNew(true);
    }
    setFormData(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const editRow = () => {
    setFormData(item);
    setEditable(true);
  };

  const cancelChanges = () => {
    setFormData(item);
    setEditable(false);
  };

  const deleteRow = async () => {
    setIsDeleting(true);
    if (isNew) {
      removeRow();
      return;
    }
    try {
      let { data } = await API(
        `admin/modals/${modalId}/modalInformations/${rowID}`,
        "delete"
      );
      dispatch(openPopUp({ message: data, type: "success" }));
      removeRow();
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
      dispatch(
        openPopUp({
          message: myTranslator(
            translation,
            selectedLang,
            "sa_model_info_alert_error_occurred"
          ),
          type: "error",
        })
      );
    }
  };

  const updateRow = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let _data = {
      modal_id: modalId,
      group: formData.group,
      label: formData.label,
      value: formData.value,
      language: formData.language,
      description: formData.description,
      // _method: "patch",
    };
    if (!isNew) {
      _data.id = rowID;
    }

    setFormErrors({});
    try {
      let { data } = await API(
        `admin/modals/${modalId}/modalInformations`,
        "post",
        _data
      );
      if (isNew) {
        setIsNew(false);
        setRowID(data.id);
      }
      afterSubmit(data[1], index);
      dispatch(
        openPopUp({
          message: myTranslator(
            translation,
            selectedLang,
            "sa_model_info_alert_success"
          ),
          type: "success",
        })
      );
      setIsLoading(false);
      setEditable(false);
    } catch (err) {
      setFormErrors(errorsSetter(err));
      dispatch(
        openPopUp({
          message: myTranslator(
            translation,
            selectedLang,
            "sa_model_info_alert_error"
          ),
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <TableRow>
        <td>
          <form id={`form${rowID}`} onSubmit={updateRow}>
            <input type="hidden" name="id" value="1" />
          </form>
        </td>
        <TableCell>
          {editable ? (
            <SelectBox
              items={!!groups_ && groups_.map((v) => v.group)}
              addNew={true}
              addHandle={(e) => setOpen(true)}
              size="small"
              name="group"
              required={true}
              error={formErrors?.group}
              initValue={formData?.group}
              handleChange={(e) => handleChange(e)}
            />
          ) : (
            formData?.group || "--"
          )}
        </TableCell>
        <TableCell>
          {editable ? (
            <InputField
              value={formData?.label}
              size="small"
              form={`form${rowID}`}
              required={true}
              name="label"
              error={formErrors?.label}
              onChange={handleChange}
              className="hide_arrows"
            />
          ) : (
            formData?.label || "--"
          )}
        </TableCell>
        <TableCell>
          {editable ? (
            <InputField
              value={formData?.value}
              size="small"
              form={`form${rowID}`}
              required={true}
              name="value"
              error={formErrors?.value}
              onChange={handleChange}
              className="hide_arrows"
            />
          ) : (
            formData?.value || "--"
          )}
        </TableCell>
        <TableCell>
          {editable ? (
            <SelectBox
              items={[
                { label: "English", value: "english" },
                { label: "Deutsch (ch)", value: "dutch" },
              ]}
              addHandle={(e) => setOpen(true)}
              size="small"
              name="language"
              required={true}
              error={formErrors?.language}
              initValue={formData?.language}
              handleChange={(e) => handleChange(e)}
            />
          ) : formData?.language === "english" ? (
            "English"
          ) : formData?.language === "dutch" ? (
            "Deutsch (ch)"
          ) : null}
        </TableCell>
        <TableCell>
          {editable ? (
            <InputField
              value={formData?.description}
              size="small"
              form={`form${rowID}`}
              required={true}
              multiline={true}
              rows={2}
              name="description"
              error={formErrors?.description}
              onChange={handleChange}
              className="hide_arrows"
            />
          ) : (
            (
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
                variant="caption1"
              >
                {formData?.description}
              </Typography>
            ) || "--"
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {editable ? (
              <>
                {!isLoading ? (
                  <Tooltip
                    title={myTranslator(
                      translation,
                      selectedLang,
                      "sa_model_info_tooltip_save"
                    )}
                  >
                    <IconButton
                      type="submit"
                      color="success"
                      form={`form${rowID}`}
                    >
                      <CheckRoundedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  "...loading"
                )}
                <Tooltip
                  title={myTranslator(
                    translation,
                    selectedLang,
                    "sa_model_info_tooltip_cancel_changes"
                  )}
                >
                  <IconButton
                    onClick={cancelChanges}
                    color="error"
                    disabled={isLoading}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Tooltip
                title={myTranslator(
                  translation,
                  selectedLang,
                  "sa_model_info_tooltip_edit"
                )}
              >
                <IconButton onClick={editRow} color="info">
                  <CreateRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={myTranslator(
                translation,
                selectedLang,
                "sa_model_info_tooltip_delete"
              )}
            >
              <IconButton
                onClick={(e) => {
                  if (isNew) {
                    deleteRow();
                    return;
                  }
                  handleClick(e);
                }}
                color="error"
                disabled={isLoading || isDeleting}
              >
                {isDeleting ? (
                  <CircularProgress color="error" size={22} />
                ) : (
                  <DeleteForeverRoundedIcon />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <Popper
            id={popperID}
            open={openPopper}
            placement="left"
            anchorEl={anchorEl}
          >
            <Box sx={modalSt}>
              <small>Are you sure?</small>
              <Button color="info" size="small" px={0} onClick={deleteRow}>
                {myTranslator(
                  translation,
                  selectedLang,
                  "sa_model_info_btn_yes"
                )}
              </Button>
              <Button color="error" size="small" px={0} onClick={handleClick}>
                {myTranslator(
                  translation,
                  selectedLang,
                  "sa_model_info_btn_no"
                )}
              </Button>
            </Box>
          </Popper>
          {/* dialog box */}
          <Dialog maxWidth="sm" open={!!open} onClose={() => setOpen(false)}>
            <DialogTitle>
              {" "}
              {myTranslator(
                translation,
                selectedLang,
                "sa_model_info_title_add_group"
              )}
            </DialogTitle>
            <DialogContent>
              <InputField
                placeholder={myTranslator(
                  translation,
                  selectedLang,
                  "sa_model_info_label_group"
                )}
                size="small"
                label={myTranslator(
                  translation,
                  selectedLang,
                  "sa_model_info_label_group"
                )}
                name="group"
                required={true}
                fullWidth
                styles={{ mb: 2, mt: 2 }}
                handleChange={(e) => setGroup(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Close</Button>
              <Button
                disabled={group ? false : true}
                onClick={() => {
                  addHandle(group);
                  setOpen(false);
                }}
              >
                {myTranslator(
                  translation,
                  selectedLang,
                  "sa_model_info_btn_add"
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableRow>
    </>
  );
};

GroupTableRow.defaultProps = {
  data: {},
  switchScreen: (e) => {},
};
export default GroupTableRow;

const modalSt = {
  border: "1px solid #ccc",
  p: 1,
  borderRadius: "7px",
  bgcolor: "#fff",
  "& .MuiButtonBase-root": {
    minWidth: "fit-content",
    ml: 0.5,
  },
};
