import React, { useState, Fragment } from "react";
import {
  Box,
  Grid,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

import {
  CustomModal,
  UsePagination,
  TableWrapper,
  MultiSelect,
  AddProjectBtn
} from "../../../../components";

import CreatePromoForm from "./create-promo-form";
import PageTitle from "../../../../hooks/page-title";
import API from "../../../../axios";
import LibraryLayout from "../library-layout";
import { openPopUp } from "../../../../store/reducer";
import myTranslator from "../../../../helpers/myTranslator";
import { _columns } from "./data";

export default function SubsidiesPanel() {
  PageTitle("Subsidies");
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [open, setOpen] = useState(false);
  const [records, setRecords] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [columns, setColumns] = useState(_columns);
  const _userRole = useSelector((state) => state.storeReducer.user.role);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(`${_userRole}/subsidies?page=${page}`, "get");
      setRecords(data);
    } catch (error) {
      if (!!records) setRecords(null);
      dispatch(openPopUp({ message: myTranslator(translation, selectedLang, "org_lib_subsidies_alert_error"), type: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const printTh = () => {
    return (
      <>
        <TableCell width="20px"></TableCell>
        {columns.map((v, _) => {
          if (!v.show) return <></>;
          return (
            <Fragment key={v.id}>
              {v.type === "simple_label" && (
                <TableCell>
                { myTranslator(translation, selectedLang, v.label)} 
                </TableCell>
              )}
            </Fragment>
          );
        })}
      </>
    );
  };

  const myPagination = (st = {}) => {
    return records?.last_page > 1 && (
      <Box component="div" sx={st}>
        <UsePagination
          total={records?.total}
          perPage={records?.per_page}
          page={records?.current_page}
          setPage={setPage}
          key={records?.last_page}
        />
      </Box>
    )
  }

  return (
    <LibraryLayout>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6">
              {myTranslator(translation, selectedLang, "org_lib_typo_promotions")}
            </Typography>
            <Typography variant="p" component="p" sx={{ mb: 2 }}>
              {myTranslator(translation, selectedLang, "org_lib_typo")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              maxWidth='200px'
            >
              <MultiSelect
                columnsList={columns}
                setColumnsList={(e) => {
                  setColumns(e);
                  setToggler(!toggler);
                }}
                label={"org_lib_comp_label_select_columns"}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {myPagination({ mb: 3 })}
            <TableWrapper
              thContent={printTh()}
              spanTd={columns.length + 1}
              isLoading={isLoading}
              isContent={records?.data.length}
            >
              {records?.data.map((subsidy, i) => {
                let rowNum =
                  (records?.current_page - 1) * records?.per_page + i + 1;
                return (
                  <TableRow key={i}>
                    <TableCell>{rowNum}</TableCell>
                    {columns[0]?.show &&
                      <TableCell>
                        {subsidy?.title}
                      </TableCell>}
                    {columns[1]?.show &&
                      <TableCell>
                        <Typography
                          textOverflow='ellipsis'
                          overflow='hidden'
                          whiteSpace='nowrap'
                          maxWidth='150px'
                        >
                          {subsidy?.description}
                        </Typography>
                      </TableCell>}
                    {columns[2]?.show &&
                      <TableCell>{subsidy?.lsg}</TableCell>
                    }
                    {columns[3]?.show &&
                      <TableCell>{moment(subsidy?.ibm, 'YYYYMMDD').format("YYYY/MM/DD")}</TableCell>
                    }
                    {columns[4]?.show &&
                      <TableCell>{subsidy?.nwbt}</TableCell>
                    }
                    {columns[5]?.show &&
                      <TableCell>{subsidy?.hbbt}</TableCell>
                    }
                    {columns[6]?.show &&
                      <TableCell>{subsidy?.vgb} CHF</TableCell>
                    }
                  </TableRow>
                );
              })}
            </TableWrapper>
            {myPagination({ mt: 3 })}
          </Grid>
        </Grid>
        {open ? (
          <CustomModal
            width="750px"
            children={
              <CreatePromoForm
                handleClose={() => setOpen(false)}
                afterSubmit={() => {
                  getData();
                  setOpen(false);
                }}
              />
            }
            handleClose={() => setOpen(false)}
          />
        ) :
          <AddProjectBtn
            tooltip={myTranslator(translation, selectedLang, "org_lib_subsidies_tooltip_create_promotion")}
            handleClick={() => setOpen(true)}
          />
        }
      </Box>
    </LibraryLayout>
  );
}

