import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
} from "@mui/material";

import API from "../../../../axios";
import {
  InputField,
  LoadingButton,
  SelectBox,
} from "../../../../components";
import { useSelector } from "react-redux";
import { openPopUp } from "../../../../store/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorsSetter } from "../../../../helpers/errors-setter";
import myTranslator from "../../../../helpers/myTranslator";

function GroupForm({ handleClose }) {
  const _user = useSelector((state) => state.storeReducer.user);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    organization_id: _user.organization_id
  });
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _userRole = useSelector((state) => state.storeReducer.user.role);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      let { data } = await API(`${_userRole}/groups`, "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(translation, selectedLang, "org_lib_group_form_alert_success"),
          type: "success",
        })
      );
      navigate(`/library/groups/${data.id}`, { id: data.id });
    } catch (error) {
      setIsLoading(false);
      setFormErrors(errorsSetter(error));
      dispatch(
        openPopUp({
          message: myTranslator(translation, selectedLang, "org_lib_group_form_alert_error"),
          type: "error",
        })
      );
    }
  };
  return (
    <>
      <Box
        component="form"
        autoCapitalize="off"
        autoComplete="off"
        onSubmit={handleForm}
        sx={{
          pb: 4,
          px: 1,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h5">
              {myTranslator(translation, selectedLang, "org_lib_group_form_typo_add_group_type")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <SelectBox
              items={techOptions}
              label={myTranslator(translation, selectedLang, "org_lib_group_form_label_group_type")}
              size="small"
              fullWidth
              required
              handleChange={(item) => {
                setFormData({ ...formData, group_type: item.target.value });
              }}
              error={formErrors?.group_type}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputField
              size="small"
              fullWidth
              required
              label={myTranslator(translation, selectedLang, "org_lib_label_title")}
              name="title"
              handleChange={handleChange}
              error={formErrors?.title}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputField
              size="small"
              fullWidth
              label={myTranslator(translation, selectedLang, "org_lib_label_description_op")}
              name="description"
              multiline
              rows="4"
              handleChange={handleChange}
              error={formErrors?.description}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <LoadingButton
                variant="contained"
                onClick={() => { }}
                disabled={isLoading}
                type="submit"
                loading={isLoading}
                title='org_lib_btn_add_to'
              />
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={handleClose}
              >
                {myTranslator(translation, selectedLang, "org_lib_btn_abort")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
GroupForm.defaultProps = {
  handleClose: () => { },
};
export default GroupForm;
const techOptions = [
  { label: 'org_lib_label_battery_storage', value: 'battery_storage' },
  { label: 'org_lib_label_energy_management', value: 'energy_management' },
  { label: 'org_lib_label_photovoltaic', value: 'photovoltaic' }
];