import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
} from "@mui/material";
import { InputField, CustomModal, SelectBox, LoadingButton } from "../../../components";
import API from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";
import { Stack } from "@mui/system";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function UpdatePowerConsumption({
  id,
  setId,
  handleClose,
  getData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    status: true,
    _method: "PATCH",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getPowerConsumption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPowerConsumption = async () => {
    try {
      const res = await API(`admin/power-consumptions/${id}`, "get");
      setGetLoading(false);
      setFormData({
        ...formData,
        name: res?.data?.name,
        building_type: res?.data?.building_type,
        heating_system: res?.data?.heating_system,
        water_system: res?.data?.water_system,
        annual_consumption: res?.data?.annual_consumption,
        status: res?.data?.active,
      });
    } catch (err) {
      dispatch(openPopUp({ message: err?.response?.message, type: "error" }));
      setIsLoading(false);
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(`admin/power-consumptions/${id}`, "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(translation, selectedLang, "sa_power_update_alert_success"),
          type: "success",
        })
      );
      handleClose(false);
      getData();
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        dispatch(
          openPopUp({
            message: err?.response?.message,
            type: "error",
          })
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
          setId(null);
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              {myTranslator(translation, selectedLang, "sa_power_update_heading")}
            </Typography>
          </Box>

          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                {inputs.map((value, index) => {
                  return (
                    <Box key={index}>
                      {!(value.type === "select_box") ? (
                        <InputField
                          type={value.type}
                          placeholder={value.label}
                          size="small"
                          label={myTranslator(translation, selectedLang, value.label)}
                          required={value.required}
                          fullWidth
                          styles={{ mb: 2 }}
                          name={value.name}
                          error={formErrors?.[value.name]}
                          value={formData?.[value.name]}
                          handleChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <SelectBox
                          items={buildTypesOpt}
                          label={myTranslator(translation, selectedLang, "sa_power_update_label_building_type")}
                          fullWidth
                          required
                          styles={{ mb: 2 }}
                          size="small"
                          initValue={formData?.building_type || ""}
                          error={formErrors?.[value.name]}
                          name="building_type"
                          handleChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      )}
                    </Box>
                  );
                })}

                <Stack justifyContent="space-between" direction="row">
                  <label>{myTranslator(translation, selectedLang, "sa_power_update_label_status")}</label>
                  <Switch
                    value={formData?.status}
                    checked={formData?.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                </Stack>
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    onClick={() => handleClose(false)}
                    sx={{
                      mt: 2,
                      mr: 1,
                    }}
                    type="button"
                  >
                    {myTranslator(translation, selectedLang, "sa_power_update_btn_ cancel")}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                    loading={isLoading}
                    title="sa_power_update_btn_update"
                  />
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
    </>
  );
}

const buildTypesOpt = [
  { label: "Detached House", value: "detached_house" },
  { label: "Apartment Building", value: "apartment_building" },
  { label: "Office Building", value: "office_building" },
  { label: "Industrial Building", value: "industrial_building" },
  { label: "Town House", value: "town_house" },
];
const inputs = [
  {
    label: "sa_power_update_label_name",
    name: "name",
    required: true,
    type: "text",
  },
  {
    label: "sa_power_update_label_building_type",
    name: "building_type",
    required: true,
    type: "select_box",
  },
  {
    label: "sa_power_update_label_heating_system",
    name: "heating_system",
    required: true,
    type: "text",
  },
  {
    label: "sa_power_update_label_water_system",
    name: "water_system",
    required: true,
    type: "text",
  },
  {
    label: "sa_power_update_label_annual_consumption",
    name: "annual_consumption",
    required: false,
    type: "number",
  },
];