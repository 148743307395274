import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
import { CustomModal, InputField, LoadingButton, SelectBox } from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function CreatePowerConsumption({
  open,
  handleClose,
  getData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const dispatch = useDispatch();

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API("admin/power-consumptions", "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(translation, selectedLang, "sa_power_create_alert_success"),
          type: "success",
        })
      );
      handleClose(false);
      getData();
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        let obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <CustomModal
        open={!!open}
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              {myTranslator(translation, selectedLang, "sa_power_create_heading")}
            </Typography>
          </Box>
          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              {inputs.map((value, index) => {
                return (
                  <Box key={index}>
                    {!(value.type === "select_box") ? (
                      <InputField
                        type={value.type}
                        placeholder={myTranslator(translation, selectedLang, value.label)}
                        size="small"
                        label={myTranslator(translation, selectedLang, value.label)}
                        required={value.required}
                        fullWidth
                        styles={{ mb: 2 }}
                        name={value.name}
                        error={formErrors?.[value.name]}
                        handleChange={(e) =>
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <SelectBox
                        items={buildTypesOpt}
                        label={myTranslator(translation, selectedLang, "sa_power_create_label_building_type")}
                        fullWidth
                        required
                        styles={{ mb: 2 }}
                        size="small"
                        initValue={formData?.building_type || ""}
                        error={formErrors?.[value.name]}
                        name="building_type"
                        handleChange={(e) =>
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    )}
                  </Box>
                );
              })}
              <Box sx={{ textAlign: "end" }}>
                <Button
                  onClick={() => handleClose(false)}
                  sx={{
                    mt: 2,
                    mr: 1,
                  }}
                  type="button"
                >
                  {myTranslator(translation, selectedLang, "sa_power_create_btn_cancel")}
                </Button>
                <LoadingButton
                  variant="contained"
                  onClick={() => { }}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                  loading={isLoading}
                  title="sa_power_create_btn_create"
                />
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>
    </>
  );
}
const buildTypesOpt = [
  { label: "sa_power_create_label_detached_house", value: "detached_house" },
  { label: "sa_power_create_label_apartment_building", value: "apartment_building" },
  { label: "sa_power_create_label_office_building", value: "office_building" },
  { label: "sa_power_create_label_industrial_building", value: "industrial_building" },
  { label: "sa_power_create_label_town_house", value: "town_house" },
];
const inputs = [
  {
    label: "sa_power_create_label_name",
    name: "name",
    required: true,
    type: "text",
  },
  {
    label: "sa_power_create_label_building_type",
    name: "building_type",
    required: true,
    type: "select_box",
  },
  {
    label: "sa_power_create_label_heating_system",
    name: "heating_system",
    required: true,
    type: "text",
  },
  {
    label: "sa_power_create_label_water_system",
    name: "water_system",
    required: true,
    type: "text",
  },
  {
    label: "sa_power_create_label_annual_consumption",
    name: "annual_consumption",
    required: false,
    type: "number",
  },
];