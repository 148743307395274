import React, { useEffect } from "react";
import {
  Box,
  Container,
  Paper,
  Typography,
  Stack,
} from "@mui/material";

import PageTitle from "../../hooks/page-title";
import {
  InputField,
  PasswordInputField,
  SelectBox,
  PopUpMsg,
  LoadingButton,
} from "../../components";
import API from "../../axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import myTranslator from "../../helpers/myTranslator";
import { errorsSetter } from "../../helpers/errors-setter";


export default function SignUp() {
  PageTitle("SignUp");
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const { token } = useParams();
  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    telephone: "",
    mobile_phone: "",
    language: "english",
    organization_id: "",
    showPassword: false,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [payload, setPayload] = React.useState({
    type: "error",
    message: "",
  });


  useEffect(() => {
    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrganization = async () => {
    try {
      const { data } = await API(`verify/organization/${token}`, "get");
      setFormData({ ...formData, "organization_id": Number(data?.id) });
    } catch (err) {
      navigate("/sign-in");
    } finally {
      setIsLoading(false);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({})
    let _data = formData;
    delete _data.showPassword;
    try {
      const { data } = await API("register", "post", _data)
      setPayload({ type: 'success', message: data });
    } catch (err) {
      if (err?.response?.status === 422) {
        formErrors(errorsSetter(err))
      } else setPayload({ type: 'error', message: err?.response?.data?.error?.message });
    } finally {
      setIsLoading(false);
      setOpen(true);
    }
  };

  return (
    <>
      <div className="sign_up_page">
        <Container maxWidth="100%" sx={{ pt: 2, pb: 2 }}>
          <Box
            component={Paper}
            sx={{
              maxWidth: "450px",
              py: 4,
              px: 4,
            }}
          >
            <Box
              sx={{
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: "center",
                }}
              >
                {myTranslator(translation, selectedLang, "gr_sign_up_typo_signup")}
                <br />
              </Typography>
              <small
                style={{
                  textAlign: "center",
                  display: "block",
                  lineHeight: 1.5,
                }}
              >
                {myTranslator(translation, selectedLang, "gr_sign_up_typo_terms")}
              </small>
            </Box>
            <Box component="form" onSubmit={handleForm} autoComplete="off" sx={{ padding: "25px 0" }}>
              <Stack direction="column" spacing={2}>
                <Stack direction={{ sm: "row", xs: "column" }} spacing={2}>
                  <InputField
                    placeholder="John doe"
                    size="small"
                    labelTop="gr_sign_up_label_firstname"
                    fullWidth
                    required
                    error={formErrors?.first_name ?? ''}
                    name="first_name"
                    handleChange={(event) => handleChange(event)}
                  />
                  <InputField
                    placeholder="gr_sign_up_label_lastname"
                    size="small"
                    fullWidth
                    required
                    labelTop="gr_sign_up_label_lastname"
                    error={formErrors?.last_name ?? ''}
                    name="last_name"
                    handleChange={(event) => handleChange(event)}
                  />
                </Stack>
                <InputField
                  placeholder="Johndoe@gmail.com"
                  size="small"
                  labelTop="gr_sign_up_label_email"
                  fullWidth
                  required
                  error={formErrors?.email ?? ''}
                  name="email"
                  handleChange={(event) => handleChange(event)}
                />
                <InputField
                  placeholder="+0987654321"
                  size="small"
                  labelTop="gr_sign_up_label_telephone"
                  fullWidth
                  required
                  error={formErrors?.telephone ?? ''}
                  name="telephone"
                  handleChange={(event) => handleChange(event)}
                />
                <InputField
                  placeholder="+0987654321"
                  size="small"
                  labelTop="gr_sign_up_label_mobilephone"
                  fullWidth
                  error={formErrors?.mobile_phone ?? ''}
                  name="mobile_phone"
                  handleChange={(event) => handleChange(event)}
                />
                <SelectBox
                  items={[
                    { label: "English", value: "english" },
                    { label: "Deutsch (CH)", value: "dutch" },
                  ]}
                  initValue={formData.language}
                  labelTop="gr_sign_up_label_language"
                  size="small"
                  fullWidth
                  name="language"
                  error={formErrors?.language ?? ''}
                  handleChange={(event) => handleChange(event)}
                />
                <PasswordInputField
                  placeholder="xxxxxx"
                  labelTop="gr_sign_up_label_password"
                  size="small"
                  fullWidth
                  required
                  name="password"
                  error={formErrors?.password ?? ''}
                  handleChange={(event) => handleChange(event)}
                />
              </Stack>
              <div style={{ marginTop: "35px" }}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  required
                  disabled={isLoading}
                  type="submit"
                  style={{
                    borderRadius: "25px",
                    textTransform: "none",
                  }}
                  loading={isLoading}
                  title="gr_sign_up_btn_signup"
                />
              </div>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="p"
                component="p"
                sx={{ textAlign: "center", color: "#6F6F6F" }}
              >
                {myTranslator(translation, selectedLang, "gr_sign_up_typo_al_have_an_account")}{" "}
                <Link className="simple_link" to="/sign-in">
                  {myTranslator(translation, selectedLang, "gr_sign_up_link_signin")}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
      <PopUpMsg
        open={open}
        type={payload.type}
        message={payload.message ?? "Error occurred"}
        handleClose={() => setOpen(false)}
      />
    </>
  );
}
