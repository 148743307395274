import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
  FormHelperText,
  Stack,
  Tabs,
  Tab,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ImageUploader, InputField, LoadingButton, SearchComboBox } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function UpdateModels() {
  const id = useParams().id;
  const manufacturer_id = useParams().manufacturer_id;

  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [value] = React.useState(0);
  const [formData, setFormData] = useState({
    title: "",
    manufacturerId: manufacturer_id,
    manufacturer: "",
    status: true,
    image: "",
    _method: "patch",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formErrors, setFormErrors] = React.useState({});

  useEffect(() => {
    getModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModels = async () => {
    setGetLoading(true);
    try {
      const res = await API(
        `admin/manufacturers/${formData.manufacturerId}/modals/${id}`,
        "get"
      );
      setFormData({
        ...formData,
        status: res?.data?.active,
        title: res?.data?.title,
        manufacturer: res?.data?.manufacturers?.manufacturer,
        manufacturerComp: res?.data?.manufacturers,
      });
      setGetLoading(false);
    } catch (err) {
      setGetLoading(false);
      let obj = {
        message: err?.response?.message,
        type: "error",
      };
      dispatch(openPopUp(obj));
    }
  };

  const handleForm = async (e, status = false) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("_method", "patch");
    fd.append("title", formData.title);
    status = formData.status ? "1" : "0";
    fd.append("status", status);
    if (formData.image !== "") fd.append("image", formData.image);
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(
        `admin/manufacturers/${formData.manufacturerId}/modals/${id}`,
        "post",
        fd
      );
      setIsLoading(false);
      let obj = {
        message: myTranslator(translation, selectedLang, "sa_model_update_alert_success"),
        type: "success",
      };
      dispatch(openPopUp(obj));
      navigate("/admin/models");
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        let obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }

      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="100%" py={3}>
      <Tooltip title="Back" placement="top">
        <IconButton onClick={() => navigate("/admin/models")}>
          <KeyboardReturnRoundedIcon />
        </IconButton>
      </Tooltip>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="basic tabs example"
          value={value}
        >
          <Tab
            label={myTranslator(translation, selectedLang, "sa_model_update_label_basic_info")}
            onClick={() =>
              navigate(
                `/admin/manufacturer/${manufacturer_id}/model/${id}`,
                {
                  id: id,
                  manufacturer_id: manufacturer_id,
                }
              )
            }
          />
          <Tab label={myTranslator(translation, selectedLang, "sa_model_update_label_groups")} onClick={() =>
            navigate(
              `/admin/manufacturer/${manufacturer_id}/model/${id}/groups`,
              {
                id: id,
                manufacturer_id: manufacturer_id,
              }
            )
          } />
        </Tabs>
      </Box>
      {getLoading ? (
        <Box display="flex" alignItems="center" pt={2} justifyContent="center">
          <CircularProgress size={30} />
        </Box>
      ) : (
        <TabPanel value={value} index={0}>
          <Box
            component='form'
            autoComplete="off"
            onSubmit={handleForm}
          >
            <Box maxWidth='400px' mx='auto'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {myTranslator(translation, selectedLang, "sa_model_update_typo_ update_model")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Typography component="label"> {myTranslator(translation, selectedLang, "sa_model_update_typo_status")}</Typography>
                    <Switch
                      checked={formData.status}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          status: e.target.checked ? true : false,
                        })
                      }
                      color="success"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    justifyContent='center'
                    alignItems='center'
                  >
                    <ImageUploader url={`modal-image/${id}`} handleImage={e => setFormData({ ...formData, image: e.target.files[0] })} />
                  </Stack>
                  {formErrors?.image !== "" && (
                    <FormHelperText sx={{ color: "red", mt: "0 !important" }}>
                      {formErrors?.image}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    placeholder={myTranslator(translation, selectedLang, "sa_model_update_label_title")}
                    label={myTranslator(translation, selectedLang, "sa_model_update_label_title")}
                    required
                    name="title"
                    fullWidth
                    size="small"
                    initValue={formData?.title}
                    error={formErrors?.title}
                    handleChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <SearchComboBox
                    record={formData?.manufacturerComp}
                    url={"admin/manufacturers?active=active&"}
                    required={false}
                    fullWidth
                    objLabel="manufacturer"
                    error={formErrors?.manufacturer}
                    size="small"
                    handleChange={(e) => {
                      setFormData({
                        ...formData,
                        manufacturer: e.manufacturer,
                        manufacturerId: e.id,
                      });
                    }}
                    label={myTranslator(translation, selectedLang, "sa_model_update_label_manufacturer")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "end", mt: 2 }}>
                    <Button
                      onClick={() => navigate("/admin/models")}
                      type="button"
                      sx={{ mr: 2 }}
                    >
                      {myTranslator(translation, selectedLang, "sa_model_update_btn_cancel")}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      disabled={isLoading}
                      type="submit"
                      loading={isLoading}
                      title='sa_model_update_btn_update'
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>
      )}
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
