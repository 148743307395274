import { React, useEffect, useState } from "react";
import {
  Button,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
  Typography,
  Tabs,
  Tab,
  Container,
  Box,
  Stack,
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import CreateIcon from "@mui/icons-material/Create";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import qs from "qs"

import API from "../../../axios";
import {
  InputField,
  SelectBox,
  TableWrapper,
  UsePagination,
  AddProjectBtn,
} from "../../../components";
import myTranslator from "../../../helpers/myTranslator";
import PageTitle from "../../../hooks/page-title";
import { generateKey } from "../../../helpers/generate-key";

export default function AdOrganizationUsers() {
  PageTitle("Users");
  const orgId = useParams().id;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(null);
  const { token } = useSelector(
    (state) => state.storeReducer?.user?.organization
  );
  const [IsFilterApply, setIsFilterApply] = useState(false);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // get project records from db
  const getData = async (flush = false) => {
    setIsLoading(true);
    var params;
    if (flush) {
      params = {
        page: page,
      };
    } else {
      params = {
        page: page,
        search: search,
        role: role,
      };
    }
    try {
      let { data } = await API(`admin/getusers/${orgId}?` + qs.stringify(params), "get");
      setRecords(data);
    } catch (error) {
      console.error("🚀 ~ file: users.js ~ getData ~ error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const usePaginate = (st = {}) => {
    return (
      records?.last_page > 1 && (
        <Box component="div" sx={st}>
          <UsePagination
            total={records?.total}
            perPage={records?.per_page}
            page={records?.current_page}
            setPage={setPage}
            key={records?.last_page}
          />
        </Box>
      )
    );
  };

  const applyFilters = () => {
    setIsFilterApply(true);
    setPage(1);
    getData();
  };

  const clearFilters = () => {
    setIsFilterApply(false);
    setPage(1);
    if (search !== "") {
      setSearch("");
    }
    if (role !== "") {
      setRole("");
    }
    getData(true);
  };

  const useFilters = () => {
    return (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems={{ xs: "center", md: "flex-end" }}
        spacing={1}
        flexWrap="wrap"
        sx={{
          "& > div,& > button": { mt: "10px !important" },
          "& > div": { width: { md: "fit-content !important", xs: "100% !important" } },
        }}
      >
        <InputField
          size="small"
          fullWidth
          label='Search'
          handleChange={(e) => setSearch(e.target.value)}
          initValue={search}
        />
        <SelectBox
          size="small"
          fullWidth
          items={[
            { label: "Manager", value: "manager" },
            { label: "Employee", value: "employee" },
            { label: "Stock Manager", value: "stock_manager" },
            { label: "Plumber", value: "plumber" },
          ]}
          label='Role'
          handleChange={(e) => setRole(e.target.value)}
          initValue={role}
        />
        {(search || role) !== '' && (
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={applyFilters}
          >

            Filter
          </Button>
        )}
        {IsFilterApply &&
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={clearFilters}
          >
            Clear
          </Button>}
      </Stack>
    );
  };

  const printTh = () => {
    return (
      <>
        <TableCell width="20px"></TableCell>
        {_columns.map((v, _) => {
          return (
            <TableCell key={v.id}>
              {v.label}
            </TableCell>
          );
        })}
        <TableCell>Actions</TableCell>
      </>
    );
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Tooltip
          title={myTranslator(
            translation,
            selectedLang,
            "sa_org_users_tooltip_back"
          )}
          placement="top"
        >
          <IconButton onClick={() => navigate("/admin/organizations")}>
            <KeyboardReturnRoundedIcon />
          </IconButton>
        </Tooltip>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs aria-label="basic tabs example" value={1}>
            <Tab
              label={myTranslator(
                translation,
                selectedLang,
                "sa_org_users_label_basic_info"
              )}
              onClick={() =>
                navigate(`/admin/organizations/${orgId}`, {
                  id: orgId,
                })
              }
            />
            <Tab
              label={myTranslator(
                translation,
                selectedLang,
                "sa_org_users_label_users"
              )}
              onClick={() =>
                navigate(`/admin/organizations/${orgId}/users`, {
                  id: orgId,
                })
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={1} index={1}>
          {useFilters()}
          {usePaginate({ mt: 2 })}
          <TableWrapper
            tableStyle={{ minWidth: "450px", mt: 3 }}
            thContent={printTh()}
            isLoading={isLoading}
            isContent={records?.data.length}
            spanTd={_columns.length + 2}
          >
            {records?.data.map((v, i) => {
              let rowNum =
                (records?.current_page - 1) * records?.per_page + i + 1;
              return (
                <TableRow key={v.id}>
                  <TableCell>{rowNum}</TableCell>
                  <TableCell>{v?.first_name + " " + v?.last_name}</TableCell>
                  <TableCell>{v?.email}</TableCell>
                  <TableCell>{v?.role}</TableCell>
                  <TableCell>
                  <Box sx={{textTransform:'capitalize'}}>{v?.status}</Box>
                </TableCell>
                  <TableCell>
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        color="info"
                        onClick={() => navigate(`/admin/organizations/${orgId}/users/${v.id}`)}
                      >
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableWrapper>
          {usePaginate({ mt: 3 })}
        </TabPanel>
      </Container>
      <AddProjectBtn
        tooltip={myTranslator(translation, selectedLang, "sa_org_users_tooltip_add_user")}
        handleClick={() => navigate(`/admin/organizations/${orgId}/users/create`)}
      />
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const _columns = [
  {
    id: generateKey(),
    label: "Name",
  },
  {
    id: generateKey(),
    label: "Email",
  },
  {
    id: generateKey(),
    label: "Role",
  },
  {
    id: generateKey(),
    label: "Status",
  },
];
