import { generateKey } from "../../../../helpers/generate-key";

const _columns = [
    {
        id: generateKey(),
        label: "org_lib_subsidies_tb_title",
        value: "component_type",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "org_lib_subsidies_tb_description",
        value: "name",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "org_lib_subsidies_tb_power_in_kwp",
        value: "item_number",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "org_lib_subsidies_tb_date_of_commissioning",
        value: "fourth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "org_lib_subsidies_tb_lean_angle_bonus",
        value: "fifth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "org_lib_subsidies_altitude_bonus",
        value: "sixth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "org_lib_subsidies_contribution",
        value: "seventh_col",
        show: true,
        type: "simple_label",
    },
];


export {
    _columns
}