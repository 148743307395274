import React, { useState, useEffect } from 'react'
import {
    Box,
    Container,
    FormControlLabel,
    Grid,
    Typography,
    Switch,
    InputAdornment,
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';

import PageTitle from '../../../hooks/page-title';
import API from '../../../axios';
import {
    InputField,
    SelectBox,
    MyLoader,
    SearchComboBox,
    SaveChangesBtn,
    InfoHeading
} from "../../../components";
import { openPopUp } from '../../../store/reducer';
import { errorsSetter } from '../../../helpers/errors-setter';
import myTranslator from '../../../helpers/myTranslator';

export default function ProjectSettings() {
    PageTitle('Project Settings');
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState({});
    const [formData, setFormData] = useState({
        _method: 'patch',
    });
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const [formErrors, setFormErrors] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/projects/${id}`, 'get');
            setIsLoading(false);
            setRecord(data);
            let date = !!data?.commissioning_date ? moment(data?.commissioning_date, 'DD.MM.YYYY').format('YYYY-MM-DD') : '';
            let calculation = data?.extended_calculation ? 1 : 0;
            setFormData({
                ...formData,
                title: data?.title || '',
                reference: data?.reference || '',
                users: data?.users || null,
                commissioning_date: date,
                tax_saving: data?.tax_saving || '',
                vat: data?.vat || '',
                language: data?.language || '',
                extended_calculation: calculation,
                organization_id: data?.organizations?.id,
            });
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleInput = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setFormErrors({});
        let _dt = formData?.commissioning_date ? moment(formData?.commissioning_date).format('DD.MM.YYYY') : '';
        let _fd = { ...formData, user_id: formData?.users?.id || '', commissioning_date: _dt };
        delete _fd.users;
        setIsLoading(true);
        try {
            await API(`${_userRole}/projects/${id}`, 'post', _fd);
            setShowUpdateBtn(false);
            dispatch(openPopUp({
                open: true,
                message: myTranslator(translation, selectedLang, "org_project_setting_alert_success"),
                type: 'success'
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                open: true,
                message: myTranslator(translation, selectedLang, "org_project_setting_alert_error"),
                type: 'error'
            }))
        }
    }

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Typography
                    variant="h5"
                    component="h5"
                    fontSize={"18px"}
                    mt={"12px"}
                    mb={"10px"}
                >
                    {myTranslator(translation, selectedLang, "org_project_setting_typo_project_settings")}
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                    }}
                >
                    {myTranslator(translation, selectedLang, "org_project_setting_typo_edit_settings")}
                </Typography>
                <Box
                    component='form'
                    autoComplete="off"
                    onSubmit={handleForm}
                >
                    <Grid container spacing={3} mt={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.title}
                                        error={formErrors?.title}
                                        fullWidth
                                        labelTop="org_project_setting_label_project_name"
                                        placeholder="Project Name"
                                        size="small"
                                        name='title'
                                        handleChange={handleInput}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {_userRole === 'manager' &&
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                                <SearchComboBox
                                    record={record?.users || ''}
                                    url={`manager/users?active=1&`}
                                    objLabel="first_name"
                                    size="small"
                                    required
                                    fullWidth
                                    labelTop="org_project_setting_label_project_manager"
                                    handleChange={(e) => {
                                        setFormData({ ...formData, users: e });
                                        setShowUpdateBtn(true);
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.reference}
                                error={formErrors?.reference}
                                fullWidth
                                labelTop="org_project_setting_label_project_reference"
                                placeholder="Project Reference"
                                size="small"
                                name='reference'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InfoHeading
                                text="org_project_setting_commissioning_date"
                                containerSx={{ mb: 1 }}
                            />
                            <InputField
                                initValue={formData.commissioning_date}
                                error={formErrors?.commissioning_date}
                                fullWidth
                                type='date'
                                size="small"
                                name='commissioning_date'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <SelectBox
                                items={langOptions}
                                labelTop="org_project_setting_label_language"
                                fullWidth
                                size="small"
                                initValue={formData?.language || ''}
                                error={formErrors?.language}
                                name='language'
                                handleChange={handleInput}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InfoHeading
                                text="org_project_setting_label_tax_savings"
                                containerSx={{ mb: 1 }}
                            />
                            <InputField
                                initValue={formData.tax_saving}
                                error={formErrors?.tax_saving}
                                placeholder='0.00 %'
                                fullWidth
                                size="small"
                                name='tax_saving'
                                type='number'
                                handleChange={handleInput}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputField
                                initValue={formData.vat}
                                error={formErrors?.vat}
                                fullWidth
                                labelTop="org_project_setting_label_vat"
                                placeholder='0.00 %'
                                type='number'
                                size="small"
                                name='vat'
                                handleChange={handleInput}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Typography>
                                {myTranslator(translation, selectedLang, "org_project_setting_typo_ext_profit_calcu")}
                            </Typography>
                            <FormControlLabel control={
                                <Switch
                                    onChange={(e) => {
                                        setFormData({ ...formData, extended_calculation: e.target.checked ? 1 : 0 });
                                        setShowUpdateBtn(true);
                                    }}
                                    checked={formData.extended_calculation === 1 ? true : false}
                                    color='success' />
                            }
                                sx={{ color: '#7d7d7d', '& .MuiTypography-body1': { fontSize: '0.85rem' } }}
                                label={myTranslator(translation, selectedLang, "org_project_setting_label_switch")} />
                        </Grid>
                    </Grid>
                    {showUpdateBtn &&
                        <SaveChangesBtn type='submit' cancel={() => setShowUpdateBtn(false)} />}
                </Box>
            </Container>
            {isLoading &&
                <MyLoader />
            }
        </>
    )
}

const langOptions = [
    { label: 'Deutsch (CH)', value: 'dutch' },
    { label: 'English', value: 'english' }
];