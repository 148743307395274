import React from 'react';
import {
    Container,
} from '@mui/material';

import {
    Header,
    PopUpMsg,
    AddProjectBtn
} from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closePopUp } from '../../../store/reducer';
import myTranslator from '../../../helpers/myTranslator';

function UserLayout({ children }) {
    const path = window.location.pathname;
    const { open, message, type } = useSelector((state) => state.storeReducer);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);
    const { role } = useSelector((state) => state.storeReducer.user) || 'manager';
    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (role !== 'manager') {
            navigate('/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header hideMenu />
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                {children}
            </Container>
            {path === '/users' &&
                <AddProjectBtn
                    tooltip={myTranslator(translation, selectedLang, "org_users_tooltip_create_user")}
                    handleClick={() => navigate('/users/create')}
                />
            }
            <PopUpMsg
                open={open}
                type={type}
                message={message ?? "error occured"}
                handleClose={() => dispatch(closePopUp())}
            />
        </>
    );
}

export default UserLayout;
