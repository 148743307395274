
const rowsOption = [
    { label: "25 Rows", value: "25" },
    { label: "50 Rows", value: "50" },
    { label: "75 Rows", value: "75" },
    { label: "100 Rows", value: "100" },
];

const langOptions = [
    { label: "Deutsch (CH)", value: "dutch" },
    { label: "English", value: "english" },
];

const techOptions = [
    { label: "org_lib_label_battery_storage", value: "battery_storage" },
    { label: "org_lib_label_energy_management", value: "energy_management" },
    { label: "org_lib_label_photovoltaic", value: "photovoltaic" },
];

const _columns = [
    {
        id: 1,
        label: "org_lib_comp_label_component_type",
        value: "component_type",
        show: true,
        type: "simple_label",
    },
    {
        id: 2,
        label: "org_lib_label_name",
        value: "name",
        show: true,
        type: "order_label",
    },
    {
        id: 3,
        label: "org_lib_comp_label_item_no",
        value: "item_number",
        show: true,
        type: "order_label",
    },
    {
        id: 4,
        label: "org_lib_comp_label_unit",
        value: "fourth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: 5,
        label: "org_lib_label_technologies",
        value: "fifth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: 6,
        label: "org_lib_label_language",
        value: "sixth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: 7,
        label: "org_lib_comp_label_status",
        value: "seventh_col",
        show: true,
        type: "simple_label",
    },
    {
        id: 8,
        label: "org_lib_comp_label_tags",
        value: "eight_col",
        show: true,
        type: "simple_label",
    },
];

export{
    rowsOption,
    langOptions,
    techOptions,
    _columns
}