import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import { SplashScreen } from "../components";
import AdminLayout from "../layout/admin/layout";
import ManagerLayout from "../layout/manager/layout";
import { ForgetPassword, Page404, SignIn, SignUp, ResetPassword } from "../pages";
import { storeUser, storeTranslation, langSetter } from "../store/reducer";
import { managerRoutes, adminRoutes } from "./my-routes";
import API from "../axios";

export default function Router() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const _isLogged = useSelector((state) => state.storeReducer.isLogged);
  const { user } = useSelector((state) => state.storeReducer);

  React.useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    if (!user) {
      let token = localStorage.getItem("@ACCESS_TOKEN");
      if (token !== null) {
        try {
          const { data } = await API("me", "get");
          dispatch(storeUser(data.user));
          dispatch(storeTranslation(data.translation));
          let _lang = localStorage.getItem('@gamaLang');
          dispatch(langSetter(_lang || 'german'));
          setIsLoading(false);
        } catch (err) {
          if (err?.response?.status === 401) {
            localStorage.removeItem("@ACCESS_TOKEN");
            return <Navigate to="/sign-in" replace />;
          } else {
            setIsLoading(false);
          }
        }
      } else {
        getLang();
      }
    } else {
      setIsLoading(false);
    }
  };

  const getLang = async () => {
    try {
      const { data } = await API("get-csvfile?general", "get");
      dispatch(storeTranslation(data));
      let _lang = localStorage.getItem('@gamaLang');
      if (_lang) {
        dispatch(langSetter(_lang));
      } else {
        localStorage.setItem('@gamaLang', 'english');
      }
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <Routes>
            <Route
              path="/sign-in"
              element={
                <CheckUser flag={_isLogged} user={user}>
                  <SignIn />
                </CheckUser>
              }
            />
            <Route
              path="/sign-up/:token"
              element={
                <CheckUser flag={_isLogged}>
                  <SignUp />
                </CheckUser>
              }
            />
            <Route
              path="/forget-password"
              element={
                <CheckUser flag={_isLogged} user={user}>
                  <ForgetPassword />
                </CheckUser>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <CheckUser flag={_isLogged} user={user}>
                  <ResetPassword />
                </CheckUser>
              }
            />
            {managerRoutes.map((_v, _i) => {
              return (
                <Route
                  key={_i}
                  path={_v.path}
                  exact
                  element={
                    <ProtectedRoute
                      flag={_isLogged}
                      layout={_v.layout}
                      type={_v.type}
                      user={user}
                    >
                      {_v.element}
                    </ProtectedRoute>
                  }
                />
              );
            })}
            {adminRoutes.map((_v, _i) => {
              return (
                <Route
                  key={_i}
                  path={_v.path}
                  exact
                  element={
                    <ProtectedRoute
                      flag={_isLogged}
                      layout={_v.layout}
                      type={_v.type}
                      user={user}
                    >
                      {_v.element}
                    </ProtectedRoute>
                  }
                />
              );
            })}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </>
      )}
    </>
  );
}

const ProtectedRoute = ({ flag, layout, user, type, children }) => {
  const role = user?.role || null;
  if (!flag) {
    return <Navigate to="/sign-in" replace />;
  }
  if ((type === "MANAGER" || type === "EMPLOYEE") && (role === "manager" || role === "employee")) {
    if (layout) {
      return <ManagerLayout children={children} />;
    }
    return children;
  } else if (type === "SUPER_ADMIN" && role === "superadmin") {
    if (layout) {
      return <AdminLayout children={children} />;
    }
    return children;
  } else {
    if (role === "manager" || role === "employee") return <Navigate to="/" replace />;
    else if (role === "superadmin") return <Navigate to="/admin" replace />;
    else return <Navigate to="*" replace />;
  }
};

const CheckUser = ({ flag, user, children }) => {
  const role = user?.role || null;
  if (flag) {
    if (role === "manager" || role === "employee") return <Navigate to="/" replace />;
    else if (role === "superadmin") return <Navigate to="/admin" replace />;
    else return <Navigate to="/sign-in" replace />;
  }
  return children;
};
