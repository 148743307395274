import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";

import {
  CustomModal,
  InputField,
  LoadingButton,
  SearchComboBox,
} from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";


export default function CreateManufacturer({
  handleClose,
  getData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    manufacturer: "",
    componentId: 0,
    component: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => { }, []);

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(
        `admin/component-types/${formData.componentId}/manufacturers`,
        "post",
        formData
      );
      setIsLoading(false);
      let obj = {
        message: "Manufacturer has been created.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        let obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              {myTranslator(translation, selectedLang, "sa_mnu_create_heading")}
            </Typography>
          </Box>
          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              <InputField
                placeholder={myTranslator(translation, selectedLang, "sa_mnu_create_label_title")}
                size="small"
                label={myTranslator(translation, selectedLang, "sa_mnu_create_label_title")}
                required
                fullWidth
                styles={{ mb: 2 }}
                name="manufacturer"
                error={formErrors?.manufacturer}
                handleChange={(e) =>
                  setFormData({ ...formData, manufacturer: e.target.value })
                }
              />
              <SearchComboBox
                initValue={formData.component}
                url={"admin/component-types?non_custom=1&"}
                fullWidth
                required={true}
                objLabel="title"
                size="small"
                handleChange={(e) => {
                  setFormData({
                    ...formData,
                    component: e.title,
                    componentId: e.id,
                  });
                }}
                label={myTranslator(translation, selectedLang, "sa_mnu_create_label_component_type")}
                styles={{ mb: 2 }}
              />
              <Box sx={{ textAlign: "end", mt: 2 }}>
                <Button
                  onClick={() => handleClose(false)}
                  sx={{
                    mr: 1,
                  }}
                  type="button"
                >
                  {myTranslator(translation, selectedLang, "sa_mnu_create_btn_ cancel")}
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  loading={isLoading}
                  title="sa_mnu_create_btn_create"
                />
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>

    </>
  );
}
