import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Container,
    Tabs,
    Tab,
    Grid,
    Paper,
    Divider,
    Stack,
    TableContainer,
    FormControlLabel,
    Switch,
    Tooltip,
    InputAdornment,
} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import API from "../../../axios";
import PageTitle from "../../../hooks/page-title";
import { errorsSetter } from "../../../helpers/errors-setter";
import { openPopUp } from "../../../store/reducer";
import {
    InfoHeading,
    InputField,
    SelectBox,
    MyLoader,
    SaveChangesBtn,
    SearchComboBox,
} from "../../../components";
import myTranslator from "../../../helpers/myTranslator";

export default function ElectricityTariffs() {
    PageTitle("Electricity Tariffs");
    const [activeTab, setActiveTab] = useState("TARIFF_LIBRARY");
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [engSupplier, setEngSupplier] = useState(null);
    const [formData, setFormData] = useState({});
    const [tariffs, setTariffs] = useState([{ label: "no items" }]);
    const [selectedID, setSelectedID] = useState(null);
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);

    const [tariffData, setTariffData] = useState({
        mon_fri: Array(24).fill("low"),
        sunday: Array(24).fill("low"),
        saturday: Array(24).fill("low"),
    });
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/electricity/tariff/${id}`, "get");
            handleData(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleTabs = (_, newValue) => {
        setActiveTab(newValue);
    };

    const handleData = (data) => {
        
        setFormData({
            consumption_tariff: data?.consumption_tariff || "",
            feed_in_tariff: data?.feed_in_tariff || "",
            performance_tariff: data?.performance_tariff || "",
            load_meter: data?.load_meter || "",
            change_to: data?.change_to || "",
            after_zero_year: data?.after_zero_year || "",
            activate_feed_in_tariff: data?.activate_feed_in_tariff || false,
        });
        if (!!data?.energysupplier && data?.energysupplier?.tariffs.length) {
            handleSupplier(data?.energysupplier);
            handleTariffData(data?.tariff_id, data?.energysupplier);
        }
    };

    const handleTariffData = (_id, _supplier = null) => {
        if (!_supplier) {
            _supplier = engSupplier;
        }
        if (!!_id) {
            setSelectedID(_id);
        }
        let _index = _supplier.tariffs.findIndex((x) => x.id === _id);
        if (_index < 0) {
            return;
        }
        let _data = _supplier.tariffs[_index];
        if (Boolean(!_data?.tariffdata.length)) {
            dispatch(
                openPopUp({
                    open: true,
                    message: myTranslator(
                        translation,
                        selectedLang,
                        "org_project_elect_tariffs_alert_warning"
                    ),
                    type: "warning",
                })
            );
            setTariffData({
                id: _data?.id,
                low_tariff: _data?.low_tariff || "",
                high_tariff: _data?.high_tariff || "",
                mon_fri: Array(24).fill("low"),
                sunday: Array(24).fill("low"),
                saturday: Array(24).fill("low"),
            });
            return;
        }
        let obj = _.groupBy(_data.tariffdata, "day");
        let mon_fri = obj.mon_to_fri.map((v) => {
            return v.value;
        });
        let saturday = obj.saturday.map((v) => {
            return v.value;
        });
        let sunday = obj.sunday.map((v) => {
            return v.value;
        });
        setTariffData({
            id: _data?.id,
            low_tariff: _data?.low_tariff || "",
            high_tariff: _data?.high_tariff || "",
            mon_fri: mon_fri,
            saturday: saturday,
            sunday: sunday,
        });
    };

    const handleSupplier = (item) => {
        setEngSupplier(item);
        let _tariffs = [];
        if (Boolean(item?.tariffs?.length)) {
            item.tariffs.forEach((x, _) => {
                return _tariffs.push({ label: x.title, value: x.id });
            });
            setTariffs(_tariffs);
        } else {
            setTariffs([{ label: "no items" }]);
        }
        setTariffData({
            mon_fri: Array(24).fill("low"),
            sunday: Array(24).fill("low"),
            saturday: Array(24).fill("low"),
        });
        setSelectedID(null);
    };

    const handleForm = async (e) => {
        e.preventDefault();
        let _fd = {
            ...formData,
            project_id: Number(id),
            energy_supp_id: engSupplier.id,
            tariff_id: tariffData.id,
            activate_feed_in_tariff: formData?.activate_feed_in_tariff ? 1 : 0,
        };
        setIsLoading(true);
        setFormErrors({});
        try {
            await API(`${_userRole}/electricity-tariffs`, "post", _fd);
            dispatch(
                openPopUp({
                    open: true,
                    message: myTranslator(
                        translation,
                        selectedLang,
                        "org_project_elect_tariffs_alert_success"
                    ),
                    type: "success",
                })
            );
            setShowUpdateBtn(false);
        } catch (error) {
            setFormErrors(errorsSetter(error));
            dispatch(
                openPopUp({
                    open: true,
                    message: myTranslator(
                        translation,
                        selectedLang,
                        "org_project_elect_tariffs_alert_error"
                    ),
                    type: "error",
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    };

    const printCells = (indexKey) => {
        return tariffData[indexKey].map((v, i) => {
            return (
                <td key={i} align="center">
                    <Tooltip title={v === "high" ? "High" : "Low"} placement="top">
                        <SquareIcon
                            color={v === "high" ? "error" : "default"}
                            sx={{ color: v === "high" ? "" : "primary.exLight" }}
                        />
                    </Tooltip>
                </td>
            );
        });
    };

    const printChangeToInput = () => {        
        return (
            <InputField
                size="small"
                labelTop="org_project_elect_tariffs_label_change_to"
                name="change_to"
                type="number"
                fullWidth
                initValue={formData?.change_to}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">Rp/kWh</InputAdornment>
                    ),
                }}
                error={formErrors?.change_to}
                handleChange={handleInput}
            />
        );
    };

    const printFeedTariffInput = () => {      
        return (
            <InputField
                size="small"
                labelTop="org_project_elect_tariffs_label_feed_in"
                name="after_zero_year"
                type="number"
                fullWidth
                initValue={formData?.after_zero_year}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">Rp/kWh</InputAdornment>
                    ),
                }}
                error={formErrors?.after_zero_year}
                handleChange={handleInput}
            />
        );
    };

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "12px", paddingBottom: "12px" }}
            >
                <Typography variant="h6" mt={"12px"} mb={"10px"}>
                    {myTranslator(
                        translation,
                        selectedLang,
                        "org_project_elect_tariffs_typo_electricity_tariffs"
                    )}
                </Typography>
                <Typography
                    variant="p"
                    sx={{
                        color: "#969696",
                        mb: 4,
                    }}
                >
                    {myTranslator(
                        translation,
                        selectedLang,
                        "org_project_elect_tariffs_typo_select"
                    )}
                </Typography>
                <Box component="form" autoComplete="off" onSubmit={handleForm}>
                    <Box sx={{ width: "100%", mt: 3, borderBottom: "1px solid #ccc" }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabs}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                            sx={{ "& .MuiTabs-flexContainer": { overflowX: "auto" } }}
                        >
                            <Tab
                                value="TARIFF_LIBRARY"
                                label={myTranslator(
                                    translation,
                                    selectedLang,
                                    "org_project_elect_tariffs_label_tariff_lib"
                                )}
                                sx={{ textTransform: "none" }}
                            />
                            <Tab
                                value="OWN_RATE_ENTRY"
                                label={myTranslator(
                                    translation,
                                    selectedLang,
                                    "org_project_elect_tariffs_label_own_rate_entry"
                                )}
                                sx={{ textTransform: "none" }}
                            />
                        </Tabs>
                    </Box>
                    <Box
                        sx={{ display: activeTab === "TARIFF_LIBRARY" ? "block" : "none" }}
                        pb={3}
                    >
                        <Grid container spacing={2} sx={{ mt: 3 }}>
                            <Grid item md={4} xs={12}>
                                <SearchComboBox
                                    labelTop="org_project_elect_tariffs_label_energy_supp"
                                    fullWidth
                                    url={`${_userRole}/energy-suppliers?`}
                                    objLabel="title"
                                    size="small"
                                    handleChange={handleSupplier}
                                    record={engSupplier}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SelectBox
                                    labelTop="org_project_elect_tariffs_label_tariff"
                                    items={tariffs}
                                    fullWidth
                                    initValue={!selectedID ? "" : selectedID}
                                    size="small"
                                    handleChange={(e) => {
                                        handleTariffData(e.target.value);
                                        setShowUpdateBtn(true);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Stack direction={{ md: "row", xs: "column" }} mt={3} spacing={2}>
                            <Box component={Paper} sx={cardStyle} maxWidth="320px">
                                <InfoHeading
                                    variant="h6"
                                    fontSize="14px"
                                    text="org_project_elect_tariffs_label_electricity"
                                />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <SquareIcon color="error" />
                                            </td>
                                            <td>
                                                <small>
                                                    {myTranslator(
                                                        translation,
                                                        selectedLang,
                                                        "org_project_elect_tariffs_tb_high_tariff"
                                                    )}
                                                </small>
                                                <Box
                                                    component="small"
                                                    color="primary.light"
                                                    display="block"
                                                >
                                                    {tariffData?.high_tariff || "0"} Rp./kWh
                                                </Box>
                                            </td>
                                            <td>
                                                <SquareIcon sx={{ color: "primary.exLight" }} />
                                            </td>
                                            <td>
                                                <small>
                                                    {myTranslator(
                                                        translation,
                                                        selectedLang,
                                                        "org_project_elect_tariffs_tb_low_tariff"
                                                    )}
                                                </small>
                                                <Box
                                                    component="small"
                                                    color="primary.light"
                                                    display="block"
                                                >
                                                    {tariffData?.low_tariff || "0"} Rp./kWh
                                                </Box>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Box>
                            <Box component={Paper} sx={cardStyle} maxWidth="320px">
                                <Typography variant="h6" fontSize="14px">
                                    {myTranslator(
                                        translation,
                                        selectedLang,
                                        "org_project_elect_tariffs_typo_performance_tariff"
                                    )}
                                </Typography>
                                <Box
                                    component="small"
                                    color="primary.light"
                                    display="block"
                                    mt={3}
                                >
                                    20.51 Rp./kWh
                                </Box>
                            </Box>
                        </Stack>
                        <Typography variant="h6" component="h6" mt={4} mb={"10px"}>
                            {myTranslator(
                                translation,
                                selectedLang,
                                "org_project_elect_tariffs_typo_tariff_hours"
                            )}
                        </Typography>
                        <TableContainer>
                            <table sx={{ "& *": { border: 0 } }}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        {Array(24)
                                            .fill("")
                                            .map((_, _i) => {
                                                return (
                                                    <td
                                                        key={_i}
                                                        align="center"
                                                        style={{ paddingBottom: 10 }}
                                                    >
                                                        {_i + 1}
                                                    </td>
                                                );
                                            })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <small>
                                                {myTranslator(
                                                    translation,
                                                    selectedLang,
                                                    "org_project_elect_tariffs_tb_mon"
                                                )}
                                                -
                                                {myTranslator(
                                                    translation,
                                                    selectedLang,
                                                    "org_project_elect_tariffs_tb_fri"
                                                )}
                                            </small>
                                        </td>
                                        {printCells("mon_fri")}
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                {myTranslator(
                                                    translation,
                                                    selectedLang,
                                                    "org_project_elect_tariffs_tb_saturday"
                                                )}
                                            </small>
                                        </td>
                                        {printCells("saturday")}
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                {myTranslator(
                                                    translation,
                                                    selectedLang,
                                                    "org_project_elect_tariffs_tb_sunday"
                                                )}
                                            </small>
                                        </td>
                                        {printCells("sunday")}
                                    </tr>
                                </tbody>
                            </table>
                        </TableContainer>
                        <Divider sx={{ mt: 4, mb: 2 }} />
                        <Typography variant="h6" component="h6" mt={4} mb={2}>
                            {myTranslator(
                                translation,
                                selectedLang,
                                "org_project_elect_tariffs_typo_view"
                            )}
                        </Typography>
                        <Box component={Paper} sx={cardStyle} maxWidth="320px">
                            <Typography variant="h6" fontSize="14px">
                                {myTranslator(
                                    translation,
                                    selectedLang,
                                    "org_project_elect_tariffs_label_electricity"
                                )}
                            </Typography>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <SquareIcon color="error" />
                                        </td>
                                        <td>
                                            <small>
                                                {myTranslator(
                                                    translation,
                                                    selectedLang,
                                                    "org_project_elect_tariffs_tb_high_tariff"
                                                )}
                                            </small>
                                            <Box
                                                component="small"
                                                color="primary.light"
                                                display="block"
                                            >
                                                {tariffData?.high_tariff || "0"} Rp./kWh
                                            </Box>
                                        </td>
                                        <td>
                                            <SquareIcon sx={{ color: "primary.exLight" }} />
                                        </td>
                                        <td>
                                            <small>
                                                {myTranslator(
                                                    translation,
                                                    selectedLang,
                                                    "org_project_elect_tariffs_tb_low_tariff"
                                                )}
                                            </small>
                                            <Box
                                                component="small"
                                                color="primary.light"
                                                display="block"
                                            >
                                                {tariffData?.low_tariff || "0"} Rp./kWh
                                            </Box>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={12}>
                                <Box my={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(formData?.activate_feed_in_tariff)}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        activate_feed_in_tariff: e.target.checked,
                                                    });
                                                    setShowUpdateBtn(true);
                                                }}
                                                color="success"
                                            />
                                        }
                                        label={myTranslator(
                                            translation,
                                            selectedLang,
                                            "org_project_elect_tariffs_label_activate"
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                {printChangeToInput()}
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                {printFeedTariffInput()}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{ display: activeTab === "OWN_RATE_ENTRY" ? "block" : "none" }}
                    >
                        <Box mt={3}>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Stack spacing={1}>
                                        <InfoHeading
                                            text="org_project_elect_tariffs_infoheading_consumption_tariff"
                                        />
                                        <InputField
                                            size="small"
                                            fullWidth
                                            placeholder="0"
                                            name="consumption_tariff"
                                            initValue={formData?.consumption_tariff}
                                            type="number"
                                            error={formErrors?.consumption_tariff}
                                            handleChange={handleInput}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Stack spacing={1}>
                                        <InfoHeading
                                            text="org_project_elect_tariffs_infoheading_feed_in_tariff"
                                        />
                                        <InputField
                                            size="small"
                                            fullWidth
                                            placeholder="0"
                                            name="feed_in_tariff"
                                            initValue={formData?.feed_in_tariff}
                                            type="number"
                                            error={formErrors?.feed_in_tariff}
                                            handleChange={handleInput}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Stack spacing={1}>
                                        <InfoHeading text="org_project_elect_tariffs_infoheading_performance_tariff"/>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            placeholder="0"
                                            name="performance_tariff"
                                            initValue={formData?.performance_tariff}
                                            type="number"
                                            error={formErrors?.performance_tariff}
                                            handleChange={handleInput}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Stack spacing={1}>
                                        <InfoHeading text="org_project_elect_tariffs_infoheading_load_payer"/>
                                        <InputField
                                            size="small"
                                            fullWidth
                                            placeholder=">30"
                                            name="load_meter"
                                            initValue={formData?.load_meter}
                                            type="number"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">kWh</InputAdornment>
                                                ),
                                            }}
                                            error={formErrors?.load_meter}
                                            handleChange={handleInput}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={Boolean(formData?.activate_feed_in_tariff)}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    activate_feed_in_tariff: e.target.checked,
                                                });
                                                setShowUpdateBtn(true);
                                            }}
                                            color="success"
                                        />
                                    }
                                    label={myTranslator(
                                        translation,
                                        selectedLang,
                                        "org_project_elect_tariffs_infoheading_performance_tariff"
                                    )}
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    {printChangeToInput()}
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    {printFeedTariffInput()}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {showUpdateBtn && (
                        <SaveChangesBtn
                            type="submit"
                            cancel={() => setShowUpdateBtn(false)}
                        />
                    )}
                </Box>
            </Container>
            {isLoading && <MyLoader />}
        </>
    );
}

const cardStyle = {
    "& p": { marginBottom: "15px" },
    "& td": { padding: "5px", color: "#464646" },
    p: 2,
    backgroundColor: "#fff",
};