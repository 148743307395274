import { generateKey } from "../../../helpers/generate-key";

const rowsOption = [
    { label: "25 Rows", value: "5" },
    { label: "50 Rows", value: "10" },
    { label: "75 Rows", value: "15" },
    { label: "100 Rows", value: "100" },
];

const langOptions = [
    { label: "Deutsch (CH)", value: "dutch" },
    { label: "English", value: "english" },
];

const techOptions = [
    { label: "sa_label_battery_storage", value: "battery_storage" },
    { label: "sa_label_energy_management", value: "energy_management" },
    { label: "sa_label_photovoltaic", value: "photovoltaic" },
];

const _columns = [
    {
        id: generateKey(),
        label: "sa_comp_panel_label_component_type",
        value: "component_type",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_name",
        value: "name",
        show: true,
        type: "order_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_item_number",
        value: "item_number",
        show: true,
        type: "order_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_unit",
        value: "fourth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_techno",
        value: "fifth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_language",
        value: "sixth_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_status",
        value: "seventh_col",
        show: true,
        type: "simple_label",
    },
    {
        id: generateKey(),
        label: "sa_comp_panel_label_tags",
        value: "eight_col",
        show: true,
        type: "simple_label",
    },
];

export {
    rowsOption,
    langOptions,
    techOptions,
    _columns
}