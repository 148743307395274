import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Stack,
  IconButton,
  TableRow,
  TableCell,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../axios";
import { openPopUp } from "../../../store/reducer";
import PageTitle from "../../../hooks/page-title";
import {
  StartIconBtn,
  SaveChangesBtn,
  MyLoader,
  TableWrapper,
  SearchComboBox,
} from "../../../components";
import myTranslator from "../../../helpers/myTranslator";
export default function Inverter() {
  PageTitle("Inverter");
  const { id } = useParams();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addComponent, setAddComponent] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [removedComponents, setRemovedComponents] = useState([]);
  const [newAddedComponents, setNewAddedComponents] = useState([]);
  const _userRole = useSelector((state) => state.storeReducer.user.role);
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(`${_userRole}/pv-inverter/${id}`, "get");
      if (!!data) {
        setTableData(data?.inverters || []);
      }
    } catch (error) {
      console.error("🚀 ~ file: inverter.js ~ getData ~ error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForm = async () => {
    let _data = {
      components: newAddedComponents,
      delete_components: removedComponents,
      project_id: Number(id),
    };
    setIsLoading(true);
    try {
      await API(`${_userRole}/pv-inverters`, "post", _data);
      setShowUpdateBtn(false);
      dispatch(
        openPopUp({
          open: true,
          message: myTranslator(translation, selectedLang, "org_project_inverter_alert_success"),
          type: "success",
        })
      );
      setRemovedComponents([]);
      setNewAddedComponents([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        openPopUp({
          open: true,
          message: myTranslator(translation, selectedLang, "org_project_inverter_alert_error"),
          type: "error",
        })
      );
    }
  };

  const removeRow = (item, index) => {
    let arr = tableData;
    arr.splice(index, 1);
    setTableData(arr);
    let arr2 = newAddedComponents;
    let _index = arr2.findIndex((x) => x === item.id);
    if (_index < 0) {
      setShowUpdateBtn(true);
      setRemovedComponents([...removedComponents, item.inverter.id]);
      return;
    } else {
      arr2.splice(_index, 1);
      setNewAddedComponents(arr2);
      setToggler(!toggler);
    }
  };

  const addRow = (item) => {
    let _index = tableData.findIndex((x) => x.inverter.id === item.id);
    if (_index !== -1) {
      dispatch(
        openPopUp({
          message: myTranslator(translation, selectedLang, "org_project_inverter_alert_warning"),
          type: "warning",
        })
      );
      return;
    }
    setTableData([...tableData, { inverter: item }]);
    setNewAddedComponents([...newAddedComponents, item.id]);
    setShowUpdateBtn(true);
    setAddComponent(!addComponent);
  };

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "12px", paddingBottom: "12px" }}
      >
        <Typography variant="h6" component="h6" mt={"12px"} mb={"10px"}>
          {myTranslator(translation, selectedLang, "org_project_inverter_typo_inverter_configuration")}
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            color: "#969696",
            mb: 4,
          }}
        >
          {myTranslator(translation, selectedLang, "org_project_inverter_typo_configure")}
        </Typography>
        <Stack
          direction={{ md: "row", xs: "column" }}
          align="stretch"
          spacing={2}
        >
          <Box sx={CardStyle}>
            <p> {myTranslator(translation, selectedLang, "org_project_inverter_typo_overview")}</p>
            <table width="100%">
              <tbody>
                <tr>
                  <td>
                    <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_overall_system")}</small>
                  </td>
                  <td>
                    <small>10,000 kWp</small>
                  </td>
                  <td>
                    <li>
                      <small>25 {myTranslator(translation, selectedLang, "org_project_inverter_typo_module")}</small>
                    </li>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_roof")} 1</small>
                  </td>
                  <td>
                    <small>10,000 kWp</small>
                  </td>
                  <td>
                    <li>
                      <small>25 {myTranslator(translation, selectedLang, "org_project_inverter_typo_module")}</small>
                    </li>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box sx={CardStyle}>
            <p>{myTranslator(translation, selectedLang, "org_project_inverter_typo_inverter")}</p>
            <table width="100%">
              <tbody>
                <tr>
                  <td
                    style={{
                      borderRight: "1px solid #C0C0C0",
                      borderBottom: "1px solid #C0C0C0",
                    }}
                  ></td>
                  <td style={{ borderBottom: "1px solid #C0C0C0" }}>
                    <b>
                      <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_solaredge")}</small>
                    </b>
                    <br />
                    <small>SE7K</small>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: "1px solid #C0C0C0" }}>
                    <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_dc_input")}</small>
                    <br />
                    <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_ac_power")}</small>
                    <br />
                    <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_no_of_dc_inputs")}</small>
                    <br />
                    <small>{myTranslator(translation, selectedLang, "org_project_inverter_typo_no_of_mpp_trackers")} </small>
                    <br />
                  </td>
                  <td>
                    <small>9.45 kW</small>
                    <br />
                    <small>7 kW</small>
                    <br />
                    <small>2</small>
                    <br />
                    <small>0</small>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Stack>
        <Typography variant="h6" component="h6" mt={4} mb={"10px"}>
          {myTranslator(translation, selectedLang, "org_project_inverter_typo_inverter")}
        </Typography>
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="flex-end"
          spacing={2}
        >
          <TableWrapper
            thContent={thLabels.map((v, i) => (
              <TableCell key={i}>{myTranslator(translation, selectedLang, v)}</TableCell>
            ))}
            spanTd={thLabels.length}
            isContent={tableData.length}
            tableStyle={{ minWidth: "800px" }}
          >
            {tableData?.map((v, i) => {
              return (
                <TableRow key={v.id}>
                  <TableCell>{v?.inverter?.name || "-"} St</TableCell>
                  <TableCell>{v?.inverter?.item_number || "-"}</TableCell>
                  <TableCell>{v?.inverter?.item_number || "-"}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    <Tooltip title={myTranslator(translation, selectedLang, "org_project_inverter_tooltip_delete")}>
                      <IconButton
                        onClick={() => removeRow(v, i)}
                        color="error"
                        disabled={isLoading}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableWrapper>
          <Box>
            <IconButton
              sx={{
                backgroundColor: "#000",
                "&:hover": { backgroundColor: "#000" },
              }}
            >
              <SimCardDownloadIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
        </Stack>
        <Stack spacing={2} direction={{ md: "row", xs: "column" }} mt={4}>
          <StartIconBtn
            title="org_project_inverter_typo_inverter"
            variant="contained"
            size="small"
            fullWidth
            styles={{ maxWidth: "150px" }}
            handleClick={() => setAddComponent(true)}
            icon={<AddIcon />}
          />
          {addComponent && (
            <ClickAwayListener onClickAway={() => setAddComponent(false)}>
              <Box>
                <SearchComboBox
                  url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                  objLabel="name"
                  label={myTranslator(translation, selectedLang, "org_project_inverter_label_components")}
                  size="small"
                  placement="top"
                  handleChange={addRow}
                />
              </Box>
            </ClickAwayListener>
          )}
        </Stack>
        {showUpdateBtn && (
          <SaveChangesBtn
            update={handleForm}
            cancel={() => setShowUpdateBtn(false)}
          />
        )}
      </Container>
      {isLoading && <MyLoader />}
    </>
  );
}

const thLabels = [
  "org_project_inverter_label_manufacturer",
  "org_project_inverter_label_typ",
  "org_project_inverter_label_item_no",
  "org_project_inverter_label_main_compo_temp",
  "org_project_inverter_label_crowd",
  "org_project_inverter_label_action",
];
const CardStyle = {
  "& p": { marginBottom: "15px" },
  "& td": { padding: "5px", color: "#464646" },
  p: 2,
  backgroundColor: "#FCFCFC",
  border: "1px solid #E8E8E8",
  borderRadius: "7px",
};
