
import { generateKey } from "../../../helpers/generate-key";

const langOptions = [
    { label: 'Deutsch (CH)', value: 'dutch' },
    { label: 'English', value: 'english' }
];

const techOptions = [
    { label: 'org_lib_label_battery_storage', value: 'battery_storage' },
    { label: 'org_lib_energy_management', value: 'energy_management' },
    { label: 'org_lib_label_photovoltaic', value: 'photovoltaic' }
];

const statusOpt = [
    { label: 'org_project_label_project_created', value: 'project_created' },
    { label: 'org_project_label_offer_expected', value: 'offer_expected' },
    { label: 'org_project_label_offer_sent', value: 'offer_sent' },
    { label: 'org_project_label_sold', value: 'sold' },
    { label: 'org_project_label_customer_service', value: 'customer_service' },
    { label: 'org_project_label_offer_made', value: 'offer_made' },
    { label: 'org_project_label_offer_changed', value: 'offer_changed' },
    { label: 'org_project_label_offer_rejected', value: 'offer_rejected' },
    { label: 'org_project_label_project_completed', value: 'project_completed' },
];

const _columns = [
    {
        id: generateKey(),
        label: "org_project_tb_project_ref",
        value: "reference",
        show: true,
        type: 'order_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_project_name",
        value: "title",
        show: true,
        type: 'order_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_technologies",
        value: "technologies",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_to_sue",
        value: "to_sue",
        show: true,
        type: 'switch_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_project_manager",
        value: "project_manager",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_customer ",
        value: "customer",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_building_address",
        value: "building_address",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_status",
        value: "project_status",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_project_costs",
        value: "projects",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "org_project_tb_last_updated",
        value: "updated_at",
        show: true,
        type: 'order_label',
    },
];

const rowsOption = [
    { label: "25 Rows", value: "25" },
    { label: "50 Rows", value: "50" },
    { label: "75 Rows", value: "75" },
    { label: "100 Rows", value: "100" },
];


export {
    langOptions,
    techOptions,
    statusOpt,
    _columns,
    rowsOption
}