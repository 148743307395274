import React, { useState, Fragment } from "react";

import {
  Box,
  Stack,
  Button,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";

import qs from "qs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import LibraryLayout from "../library-layout";
import PageTitle from "../../../../hooks/page-title";
import API from "../../../../axios";
import {
  SelectBox,
  InputField,
  AddProjectBtn,
  CustomModal,
  UsePagination,
  TableWrapper,
  OrderDataBtn,
  MultiSelect,
  FilterWrapper
} from "../../../../components";
import GroupForm from "./group-form";
import { icon1, icon2, icon3 } from "../../../../assets";
import myTranslator from "../../../../helpers/myTranslator";

export default function GroupPanel() {
  PageTitle("The Group");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [toggler, setToggler] = React.useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  // filters
  const [page, setPage] = useState(1);
  const [columns, setColumns] = React.useState(_columns);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [language, setLanguage] = useState("");
  const [technologies, setTechnologies] = useState("");
  const [orderBy, setOrderBy] = useState({
    name: "created_at",
    order: "desc",
  });
  const [IsFilterApply, setIsFilterApply] = React.useState(false);
  const navigate = useNavigate();
  const _userRole = useSelector((state) => state.storeReducer.user.role);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, perPage]);

  const applyFilters = () => {
    setIsFilterApply(true);
    setPage(1);
    getData();
  };

  const clearFilters = () => {
    setIsFilterApply(false);
    if (search !== "") {
      setSearch("");
    }
    if (language !== "") {
      setLanguage("");
    }
    if (technologies !== "") {
      setTechnologies("");
    }
    if (orderBy.name !== "created_at" && orderBy.order !== "desc") {
      setOrderBy({
        name: "created_at",
        order: "desc",
      });
    }
    setPage(1);
    getData(true);
  };

  const getData = async (flush = false) => {
    setIsLoading(true);
    try {
      var params;
      if (flush) {
        params = {
          page: page,
          per_page: perPage,
          column: 'created_at',
          orderby: 'desc',
        };
      } else {
        params = {
          page: page,
          per_page: perPage,
          search: search,
          technology: technologies,
          language: language,
          column: orderBy.name,
          orderby: orderBy.order,
        };
      }
      let { data } = await API(`${_userRole}/groups?` + qs.stringify(params), "get");
      setRecords(data);
      setIsLoading(false);
    } catch (error) {
      if (!!records) setRecords(null);
      setIsLoading(false);
    }
  };

  const myFilters = () => {
    return (
      <>
        <InputField
          size="small"
          label={myTranslator(translation, selectedLang, "org_lib_label_search")}
          fullWidth
          handleChange={(e) => setSearch(e.target.value)}
          initValue={search}
        />
        <SelectBox
          items={techOptions}
          label={myTranslator(translation, selectedLang, "org_lib_label_technologies")}
          size="small"
          fullWidth
          initValue={technologies}
          handleChange={(e) => setTechnologies(e.target.value)}
        />
        <SelectBox
          items={langOptions}
          label={myTranslator(translation, selectedLang, "org_lib_label_language")}
          size="small"
          fullWidth
          initValue={language}
          handleChange={(e) => setLanguage(e.target.value)}
        />
      </>
    );
  };

  const tableFilters = () => {
    return (
      <>
        <MultiSelect
          columnsList={columns}
          setColumnsList={(e) => {
            setColumns(e);
            setToggler(!toggler);
          }}
          label={"org_lib_comp_label_select_columns"}
        />
        <SelectBox
          label={myTranslator(translation, selectedLang, "org_lib_comp_label_shown")}
          size="small"
          fullWidth
          items={rowsOption}
          handleChange={(e) => {
            setPage(1);
            setPerPage(e.target.value);
          }}
          initValue={perPage}
        />
      </>
    )
  }

  const printTh = () => {
    return (
      <>
        <TableCell width="20px">
        </TableCell>
        {columns.map((v, _) => {
          if (!v.show) return <></>
          return (<Fragment key={v.id}>
            {v.type === 'simple_label' &&
              <TableCell>
                {myTranslator(translation, selectedLang, v.label)}
              </TableCell>
            }
            {v.type === 'order_label' &&
              <TableCell>
                <OrderDataBtn handleBtn={setOrderBy} selected={orderBy.name} value={v.value} title={myTranslator(translation, selectedLang, v.label)} />
              </TableCell>
            }
          </Fragment>);
        })}
        <TableCell>
          {myTranslator(translation, selectedLang, "org_lib_comp_label_actions")}
        </TableCell>
      </>
    );
  };

  const myPagination = (st = {}) => {
    return records?.last_page > 1 && (
      <Box component="div" mb={3} sx={st}>
        <UsePagination
          total={records?.total}
          perPage={records?.per_page}
          page={records?.current_page}
          setPage={setPage}
          key={records?.last_page}
        />
      </Box>
    )
  }

  return (
    <LibraryLayout>
      <Box>
        <FilterWrapper
          isApplied={IsFilterApply}
          filters={myFilters()}
          btn={(search || language || technologies) !== '' && (
            <Button
              variant="contained"
              sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
              onClick={applyFilters}
            >
              {myTranslator(translation, selectedLang, "org_lib_add_comp_btn_filter")}
            </Button>
          )}
          tableFilters={tableFilters()}
          clearFilters={clearFilters}
        />
        <Box>
          {myPagination({ mb: 3 })}
          <TableWrapper
            thContent={printTh()}
            spanTd={columns.length + 2}
            isLoading={isLoading}
            isContent={records?.data.length}
          >
            {records?.data.map((v, i) => {
              let rowNum = (records?.current_page - 1) * records?.per_page + i + 1;
              return (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "center" }}>{rowNum}</TableCell>
                  {columns[0].show &&
                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                      >
                        {v?.group_type === 'energy_management' &&
                          <Tooltip title={myTranslator(translation, selectedLang, "org_lib_label_photovoltaic")} placement="top">
                            <div className="cube">
                              <img src={icon3} alt='' />
                            </div>
                          </Tooltip>
                        }
                        {v?.group_type === 'battery_storage' &&
                          <Tooltip title={myTranslator(translation, selectedLang, "org_lib_label_battery_storage")} placement="top">
                            <div className="cube">
                              <img src={icon2} alt='' />
                            </div>
                          </Tooltip>
                        }
                        {v?.group_type === 'photovoltaic' &&
                          <Tooltip title={myTranslator(translation, selectedLang, "org_lib_label_photovoltaic")} placement="top">
                            <div className="cube">
                              <img src={icon1} alt='' />
                            </div>
                          </Tooltip>
                        }
                      </Stack>
                    </TableCell>
                  }
                  {columns[1].show &&
                    <TableCell>
                      {v?.title || '-'}
                    </TableCell>
                  }
                  {columns[2].show &&
                    <TableCell>
                      -
                    </TableCell>
                  }
                  <TableCell>
                    <Tooltip title={myTranslator(translation, selectedLang, "org_lib_tooltip_edit")} placement='top'>
                      <IconButton
                        onClick={() => navigate(`/library/groups/${v.id}`, { id: v.id })}
                        color='info'
                      >
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableWrapper>
          {myPagination({ mt: 4 })}
        </Box>
      </Box>
      {openModal ? (
        <CustomModal
          width="450px"
          children={<GroupForm handleClose={() => setOpenModal(false)} getData={getData} />}
          handleClose={() => setOpenModal(false)}
        />
      ) : (
        <AddProjectBtn
          tooltip={myTranslator(translation, selectedLang, "org_lib_tooltip_create_compo")}
          handleClick={() => setOpenModal(true)} />
      )}
    </LibraryLayout>
  );
}

const rowsOption = [
  { label: "25 Rows", value: "25" },
  { label: "50 Rows", value: "50" },
  { label: "75 Rows", value: "75" },
  { label: "100 Rows", value: "100" },
];

const langOptions = [
  { label: 'Deutsch (CH)', value: 'dutch' },
  { label: 'English', value: 'english' }
];


const techOptions = [
  { label: 'org_lib_label_battery_storage', value: 'battery_storage' },
  { label: 'org_lib_label_energy_management', value: 'energy_management' },
  { label: 'org_lib_label_photovoltaic', value: 'photovoltaic' }
];
const _columns = [
  {
    id: 1,
    label: 'org_lib_label_technologies',
    value: "technologies",
    show: true,
    type: 'simple_label'
  },
  {
    id: 2,
    label: 'org_lib_comp_label_name',
    value: "title",
    show: true,
    type: 'order_label'
  },
  {
    id: 3,
    label: 'org_lib_label_language',
    value: "language",
    show: true,
    type: 'simple_label'
  },
];
