import React, { useState } from "react";
import {
  Box,
  Stack,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { InputField, LoadingButton, SelectBox } from "../../../../components";
import { openPopUp } from "../../../../store/reducer";
import { errorsSetter } from "../../../../helpers/errors-setter";
import API from "../../../../axios";
import moment from "moment/moment";
import myTranslator from "../../../../helpers/myTranslator";

export default function CreatePromoForm({
  handleClose = () => {},
  afterSubmit = () => {},
}) {
  const _user = useSelector((state) => state.storeReducer.user);
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    organization_id: _user.organization_id,
    nw: "false",
    hb: "false",
    ev: "false",
  });
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const _userRole = useSelector((state) => state.storeReducer.user.role);

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: String(checked) });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(`${_userRole}/subsidies`, "post", {
        ...formData,
        ibm: Number(moment(formData.ibm).format("YYYYMMDD")),
      });
      dispatch(
        openPopUp({
          message: myTranslator(
            translation,
            selectedLang,
            "org_lib_subsidies_cr_promo_alert_success"
          ),
          type: "success",
        })
      );
      afterSubmit();
    } catch (error) {
      setFormErrors(errorsSetter(error));
      dispatch(
        openPopUp({
          message: myTranslator(
            translation,
            selectedLang,
            "org_lib_subsidies_cr_promo_alert_error"
          ),
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        component="form"
        autoCapitalize="off"
        autoComplete="off"
        onSubmit={handleForm}
        sx={{
          pb: 4,
          px: 1,
        }}
      >
        <Typography variant="h6" component="h5">
          {myTranslator(
            translation,
            selectedLang,
            "org_lib_subsidies_cr_promo_typo_heading"
          )}
        </Typography>
        <Box sx={{ borderBottom: "1px solid #ccc", mt: 2 }}>
          <Typography
            variant="p"
            component="p"
            sx={{
              borderBottom: "1px solid",
              pb: 1,
              borderColor: "primary.main",
              width: "fit-content",
            }}
          >
            {myTranslator(translation, selectedLang, "org_lib_typo_deutch")}
          </Typography>
        </Box>
        {/* Form */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="p" component="p" sx={{ mt: 3, mb: 2 }}>
            {myTranslator(
              translation,
              selectedLang,
              "org_lib_subsidies_cr_promo_typo_definition_promo"
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <InputField
                size="small"
                fullWidth
                required
                labelTop={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_tb_title"
                )}
                placeholder={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_tb_title"
                )}
                name="title"
                handleChange={handleChange}
                error={formErrors?.title}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                type="number"
                size="small"
                fullWidth
                required
                labelTop={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_tb_power_in_kwp"
                )}
                placeholder="0"
                name="lsg"
                handleChange={handleChange}
                error={formErrors?.lsg}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                type="date"
                size="small"
                fullWidth
                required
                labelTop={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_tb_date_of_commissioning"
                )}
                name="ibm"
                handleChange={handleChange}
                error={formErrors?.ibm}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectBox
                items={[
                  {
                    label: myTranslator(
                      translation,
                      selectedLang,
                      "org_lib_subsidies_cr_promo_label_attached"
                    ),
                    value: "0",
                  },
                  {
                    label: myTranslator(
                      translation,
                      selectedLang,
                      "org_lib_subsidies_cr_promo_label_detached"
                    ),
                    value: "1",
                  },
                  {
                    label: myTranslator(
                      translation,
                      selectedLang,
                      "org_lib_subsidies_cr_promo_label_integrated"
                    ),
                    value: "2",
                  },
                ]}
                size="small"
                labelTop={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_cr_promo_label_type_of_plant_construction"
                )}
                required
                fullWidth
                name="kat"
                handleChange={handleChange}
                error={formErrors?.kat}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="nw"
                    value={Boolean(formData?.nw) || false}
                    color="success"
                    onChange={handleCheck}
                  />
                }
                label={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_cr_promo_label_75_degrees"
                )}
                re
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hb"
                    value={Boolean(formData?.hb) || false}
                    color="success"
                    onChange={handleCheck}
                  />
                }
                label={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_cr_promo_label_high_bonus_1500m"
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ev"
                    value={Boolean(formData?.ev) || false}
                    color="success"
                    onChange={handleCheck}
                  />
                }
                label={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_cr_promo_label_no_own_consumption"
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                size="small"
                fullWidth
                labelTop={myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_tb_description"
                )}
                placeholder={`${myTranslator(
                  translation,
                  selectedLang,
                  "org_lib_subsidies_tb_description"
                )}${"..."}`}
                name="description"
                multiline
                rows="3"
                handleChange={handleChange}
                error={formErrors?.description}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} mt={3}>
            <LoadingButton
              variant="contained"
              disabled={isLoading}
              type="submit"
              loading={isLoading}
              title={myTranslator(
                translation,
                selectedLang,
                "org_lib_btn_add_to"
              )}
            />
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              {myTranslator(translation, selectedLang, "org_lib_btn_abort")}
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
