import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Switch,
  Typography,
  Stack
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { InputField, CustomModal, LoadingButton } from "../../../components";
import API from "../../../axios";
import { openPopUp } from "../../../store/reducer";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function UpdateComponentType({
  id,
  setId,
  handleClose,
  getData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    title: "",
    is_custom: true,
    cycling: true,
    status: true,
    _method: "PATCH",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getComponentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponentTypes = async () => {
    try {
      const res = await API(`admin/component-types/${id}`, "get");
      setGetLoading(false);
      setFormData({
        ...formData,
        title: res?.data?.title,
        status: res?.data?.active,
        cycling: res?.data?.cycling,
        is_custom: res?.data?.is_custom,
      });
    } catch (err) {
      let obj = {
        message: err?.response?.message,
        type: "error"
      };
      dispatch(openPopUp(obj));
      setIsLoading(false);
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(`admin/component-types/${id}`, "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(translation, selectedLang, "sa_comp_update_alert_success"),
          type: "success",
        })
      );
      handleClose(false);
      getData();
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        dispatch(
          openPopUp({
            message: err?.response?.message,
            type: "error",
          })
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
          setId(null);
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
              {myTranslator(translation, selectedLang, "sa_comp_update_heading")}
            </Typography>
          </Box>

          {getLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Box>
          ) : (
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                <InputField
                  placeholder={myTranslator(translation, selectedLang, "sa_comp_update_label_title")}
                  size="small"
                  label={myTranslator(translation, selectedLang, "sa_comp_update_label_title")}
                  required
                  fullWidth
                  styles={{ mb: 2 }}
                  name="title"
                  error={formErrors.title}
                  value={formData.title}
                  handleChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
                <Stack justifyContent="space-between" direction="row">
                  <label>{myTranslator(translation, selectedLang, "sa_comp_update_label_custom")}</label>
                  <Switch
                    value={formData.is_custom}
                    checked={formData.is_custom}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        is_custom: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                </Stack>
                {formData?.is_custom ?
                  <Stack justifyContent="space-between" direction="row">
                    <label>{myTranslator(translation, selectedLang, "Cycling")}</label>
                    <Switch
                    checked={formData.cycling}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          cycling: e.target.checked ? 1 : 0,
                        })
                      }
                      color="success"
                    />
                  </Stack>
                  : null
                }
                <Stack justifyContent="space-between" direction="row">
                  <label>{myTranslator(translation, selectedLang, "sa_comp_update_label_status")}</label>
                  <Switch
                    value={formData.status}
                    checked={formData.status}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        status: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                </Stack>
                <Box sx={{ textAlign: "end", mt: 2 }}>
                  <Button
                    onClick={() => handleClose(false)}
                    sx={{ mr: 1 }}
                    type="button"
                  >
                    {myTranslator(translation, selectedLang, "sa_comp_update_btn_cancel")}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    disabled={isLoading}
                    type="submit"
                    loading={isLoading}
                    title="sa_comp_update_btn_update"
                  />
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </CustomModal>
    </>
  );
}
