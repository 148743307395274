import React, { useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Stack
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { useDispatch, useSelector } from "react-redux";
import qs from 'qs';

import { TableWrapper, UsePagination, InputField, SelectBox } from "../../../components";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { generateKey } from "../../../helpers/generate-key";
import { useNavigate } from "react-router-dom";
import UserLayout from "./user-layout";
import { openPopUp } from "../../../store/reducer";
import myTranslator from "../../../helpers/myTranslator";

function Users() {
  PageTitle("Users");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(null);
  const { token } = useSelector(
    (state) => state.storeReducer?.user?.organization
  );
  const [IsFilterApply, setIsFilterApply] = React.useState(false);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedLang, translation } = useSelector(
    (state) => state.storeReducer
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // get project records from db
  const getData = async (flush = false) => {
    setIsLoading(true);
    var params;
    if (flush) {
      params = {
        page: page,
      };
    } else {
      params = {
        page: page,
        search: search,
        role: role,
      };
    }
    try {
      let { data } = await API('manager/users?' + qs.stringify(params), "get");
      setRecords(data);
    } catch (error) {
      console.error("🚀 ~ file: users.js ~ getData ~ error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const usePaginate = (st = {}) => {
    return (
      records?.last_page > 1 && (
        <Box component="div" sx={st}>
          <UsePagination
            total={records?.total}
            perPage={records?.per_page}
            page={records?.current_page}
            setPage={setPage}
            key={records?.last_page}
          />
        </Box>
      )
    );
  };

  const applyFilters = () => {
    setIsFilterApply(true);
    setPage(1);
    getData();
  };

  const clearFilters = () => {
    setIsFilterApply(false);
    setPage(1);
    if (search !== "") {
      setSearch("");
    }
    if (role !== "") {
      setRole("");
    }
    getData(true);
  };

  const useFilters = () => {
    return (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems={{ xs: "center", md: "flex-end" }}
        spacing={1}
        flexWrap="wrap"
        sx={{
          "& > div,& > button": { mt: "10px !important" },
          "& > div": { width: { md: "fit-content !important", xs: "100% !important" } },
        }}
      >
        <InputField
          size="small"
          fullWidth
          label='Search'
          handleChange={(e) => setSearch(e.target.value)}
          initValue={search}
        />
        <SelectBox
          size="small"
          fullWidth
          items={[
            { label: "Manager", value: "manager" },
            { label: "Employee", value: "employee" },
            { label: "Stock Manager", value: "stock_manager" },
            { label: "Plumber", value: "plumber" },
          ]}
          label='Role'
          handleChange={(e) => setRole(e.target.value)}
          initValue={role}
        />
        {(search || role) !== '' && (
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={applyFilters}
          >

            {myTranslator(translation, selectedLang, "sa_tariffs_get_btn_filter")}
          </Button>
        )}
        {IsFilterApply &&
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={clearFilters}
          >
            {myTranslator(translation, selectedLang, "sa_tariffs_get_btn_clear")}
          </Button>}
      </Stack>
    );
  };

  const printTh = () => {
    return (
      <>
        <TableCell width="20px"></TableCell>
        {_columns.map((v, _) => {
          return (
            <TableCell key={v.id}>
              {myTranslator(translation, selectedLang, v.label)}
            </TableCell>
          );
        })}
        <TableCell>Actions</TableCell>
      </>
    );
  };

  const handleInvitation = () => {
    if (window.location.hostname === "localhost") {
      navigator.clipboard.writeText("http://localhost:3000/sign-up/" + token);
    } else {
      navigator.clipboard.writeText(
        window.location.hostname + "/sign-up/" + token
      );
    }
    dispatch(
      openPopUp({
        message: myTranslator(translation, selectedLang, "org_users_alert_success"),
        type: "success",
      })
    );
  };

  return (
    <UserLayout>
      <>
        <Typography variant="h6" mb={2}>
        {myTranslator(translation, selectedLang, "org_users_typo_users")}
        </Typography>
        <Box textAlign="end" mb={2}>
          <Tooltip title={myTranslator(translation, selectedLang, "org_users_tooltip_create_invitation_link")} placement="top">
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              onClick={handleInvitation}
            >
             {myTranslator(translation, selectedLang, "org_users_typo_invitation")}
            </Button>
          </Tooltip>
        </Box>
        {usePaginate({ mb: 3 })}
        <TableWrapper
          tableStyle={{ minWidth: "450px" }}
          thContent={printTh()}
          isLoading={isLoading}
          isContent={records?.data.length}
          spanTd={_columns.length + 2}
        >
          {records?.data.map((v, i) => {
            let rowNum =
              (records?.current_page - 1) * records?.per_page + i + 1;
            return (
              <TableRow key={v.id}>
                <TableCell>{rowNum}</TableCell>
                <TableCell>{v?.first_name + " " + v?.last_name}</TableCell>
                <TableCell>{v?.email}</TableCell>
                <TableCell>{v?.role}</TableCell>
                <TableCell>
                  <Box sx={{textTransform:'capitalize'}}>{v?.status}</Box>
                </TableCell>
                <TableCell>
                  <Tooltip title={myTranslator(translation, selectedLang, "org_lib_tooltip_edit")} placement="top">
                    <IconButton
                      color="info"
                      onClick={() => navigate(`/users/${v.id}`)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableWrapper>
        {usePaginate({ mt: 3 })}
      </>
    </UserLayout>
  );
}

export default Users;

const _columns = [
  {
    id: generateKey(),
    label: "org_users_tb_name",
  },
  {
    id: generateKey(),
    label: "org_users_tb_email",
  },
  {
    id: generateKey(),
    label: "org_users_tb_role",
  },
  {
    id: generateKey(),
    label: "org_users_tb_status",
  },
];
